/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.ReviewStar {
    --star-outline-color: var(--secondary-dark-color);

    // reset from input default styles
    display: inline;
    margin: 0;
    padding: 0;
    background: none;
    border: 0;
    transition: color 150ms ease-in;
    color: $white;
    text-shadow: -1px 0 var(--star-outline-color),
        0 1px var(--star-outline-color),
        1px 0 var(--star-outline-color),
        0 -1px var(--star-outline-color);
    font-size: 40px;
    line-height: 1;
    height: unset;
    /* stylelint-disable-next-line font-family-no-missing-generic-family-keyword */
    font-family: Times; // make sure ★ appears correctly
    letter-spacing: 3px;

    @supports (-webkit-touch-callout: none) {
        /* CSS specific to iOS devices */
        height: 40px;
    }

    &::after {
        content: '★';
        font-family: serif;
    }

    &[id*='=='] {
        margin-block-start: 0;
    }

    &:checked,
    &:hover,
    &[checked],
    &:checked ~ input,
    &:hover ~ input,
    &[checked] ~ input {
        --star-outline-color: var(--primary-base-color);

        color: var(--primary-base-color);
    }

    &:focus {
        border: 0;
    }
}
