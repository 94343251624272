/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/* stylelint-disable */ 
.BlogPosts {
    background-color: var(--color-white);
    
    .ZMBlog {
        padding-inline-start: 5%;

        @include desktop {
            display: grid;
            grid-template-columns: 22% 78%;
            padding-block: 80px;
            height: 733px;
        }

        @include mobile {
            height: 535px;
            padding-block: 30px;
        }
        &-Wrap{
            width: 302px;
            height: 157px;
            display: flex;
            flex-direction: column;
            row-gap: 40px;
            &-Title{
                font-family: 'Lato';
                font-style: normal;
                font-weight: 600;
                font-size: 40px;
                line-height: 46px;
                color: #000000;
            }
            &-Description{
                font-family: 'Lato';
                font-style: normal;
                font-weight: 300;
                font-size: 16px;
                line-height: 24px;
                color: #393939;
            }
        }

        &-title {
            height: 43px;
            display: flex;
            align-items: center;
            justify-content: center;

            span {
                background-image: url('https://www.zaynmyza.com/media/new_zm/image_28.png');
                background-repeat: no-repeat;
                background-size: cover;
                line-height: 43px;
                font-family: 'Lato';
                font-style: normal;
                font-weight: 600;
                text-align: center;
                padding-inline: 20px;
                color: #161616;

                @include desktop {
                    font-size: 32px;
                }

                @include mobile {
                    font-size: 20px;
                }

            }

        }

        &-slides {
            display: flex;
            flex-direction: row;
            overflow: scroll;

            @include mobile {
                margin-block-start: 24px;
            }
            
            ul {
                display: flex;
                flex-direction: row;
                overflow: scroll;
                
                li {
                    margin-inline-start: 10px;
                }
            }
        }

        &-Container {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 0px;
            gap: 24px;
            @include desktop {
                width: 411px;
                height: 552px;
            }

            @include mobile {
                width: 242px;
                height: 390px;
            }
        }
        &-Image {
            @include desktop {
                width: 411px;
            }

            @include mobile {
                width: 242px;
            }
        }
        &-Content {
            display: flex;
            flex-direction: column;
            @include desktop {
                width: 411px;
            }

            @include mobile {
                width: 242px;
            }
        }
        &-Title {
            height: 54px;
            overflow: hidden;
            font-family: 'Lato';
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 27px;
            color: #000000;
            @include mobile {
                height: 36px;
                font-size: 16px;
                line-height: 16px;
            }
        }
        &-Description {
            height: 48px;
            overflow: hidden;
            font-family: 'Lato';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            @include mobile {
                height: 60px;
                font-size: 14px;
                line-height: 20px;
            }
        }
        &-Link {
            padding: 3px 10px;
            background-color: #DE4D47;
            color: white;
            width: fit-content;
            font-size: 14px;
            margin-right: 0px;
            margin-left: auto;
            @include mobile {
                padding: 3px 10px;
                font-size: 12px;                
            }            
        }
    }
}
