/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../../../src/style/abstract/variables';
@import '../../../../src/style/abstract/media';
@import '../../../../src/style/abstract/button';
@import '../../../../src/style/abstract/loader';
@import '../../../../src/style/abstract/icons';
@import '../../../../src/style/abstract/parts';

$icon-pagebuilder-caret-up__content: '\f104';
$icon-pagebuilder-caret-down__content: '\f101';
$icon-pagebuilder-caret-next__content: '\f102';
$icon-pagebuilder-caret-prev__content: '\f103';

@import 'colors';
@import 'icons';
@import 'layout';
@import 'content-type/import';

//
//  Common
//  _____________________________________________
li::before {
    content: '';
}

.cms-content-important {
    background-color: $color-white-smoke;
    color: $color-gray20;
    font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 300;
    line-height: 1.1;
    margin: 0 0 35px -20px;
    padding: 20px;
}

.pagebuilder-full-width {
    float: left;
    width: 100%;
}

.pagebuilder-content-type {
    box-sizing: border-box;
    margin-bottom: 20px;
}


.pagebuilder-mobile-hidden {
    @include mobile {
        display: none !important;
    }
}

.pagebuilder-mobile-only {
    @include desktop {
        display: none !important;
    }
}
