/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../../../../src/style/abstract/variables';
@import '../../../../../src/style/abstract/media';
@import '../../../../../src/style/abstract/button';
@import '../../../../../src/style/abstract/loader';
@import '../../../../../src/style/abstract/icons';
@import '../../../../../src/style/abstract/parts';

.HtmlSlider {
    &-Slide {
        padding-inline: var(--slide-gap);
        &Inner {
            box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.3);
            border-radius: 5px;
            overflow: hidden;
            margin-bottom: 4px;
        }
        video {
            width: 100%;
            object-fit: cover;
            object-position: center;
        }
        button {
            @include mobile {
                width: 100%;
            }
        }
    }
    .slick-list{
        @include mobile {
            padding:0 20% 0 0 !important;
        }
    }
}
