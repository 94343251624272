/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/* stylelint-disable */ 
.OtpLogin {
    background: var(--color-white);
    padding: 24px;
    &-Heading {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 21px;
        letter-spacing: 0.05em;
        color: #161616;
        margin-bottom: 24px;
    }
    &-Input {
        &-Wrapper {
            display: flex;
            align-items: center;
            gap: 16px;
        }
        &-Content {
            display: flex;
        }
        &-Country {
            font-family: 'Lato';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            letter-spacing: 0.05em;
            color: #000000;
            background: #FFFFFF;
            border: 1px solid #000000;
            display: flex;
            align-items: center;
            justify-content: center;
            padding-inline: 10px;
        }
        &-Field {
            color: #000000;
            background: #FFFFFF;
            // border: 1px solid #000000;
            border-left: none;
            padding-inline: 13.51px;
            min-width: 332px;
            padding-block: 8px;
            height: 38px;
            @include mobile {
                min-width: unset;
            }
        }
        &-Heading {
            font-family: 'Lato';
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            line-height: 14px;
            color: #000000;
            margin-bottom: 8px;
        }
        &-Button {
            padding: 8px 16px;
            width: 130px;
            height: 48px;
            background: #161616;
            font-family: 'Lato';
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            color: #FFFFFF;
            cursor: pointer;
        }
    }
    &-HelperText {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        color: #474747;
        margin-top: 9px;
    }
    &-Popup {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 100;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    &-PopupOverlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba($color: #000000, $alpha: 0.4);
    }
    &-PopupContent {
        width: 428px;
        height: 488px;
        background: var(--color-white);
        padding: 73px 36px;
    }
    &-VerifyOtp {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    &-VerifyHeading {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 600;
        font-size: 23px;
        line-height: 32px;
        text-align: center;
        letter-spacing: 0.05em;
        color: #161616;
        margin-bottom: 40px;
    }
    &-ExpireTimer {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: #818284;
        margin-block: 40px 40px;
        text-align: center;
    }
    &-ResendButton {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: #DE4D47;
        margin-block: 40px 40px;
        cursor: pointer;
    }
    &-VerifyButton {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 8px 16px;
        gap: 8px;
        width: 130px;
        height: 48px;
        background: #161616;
        font-family: 'Lato';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: var(--color-white);
        cursor: pointer;
    }
    &-VerifyInput {
        > div {
            gap: 16px;
            @include mobile {
                gap: 8px;
                justify-content: center;
            }
            >input {
                margin-top: 0;
                padding: 0;
                width: 46px !important;
                height: 56px;
                font-family: 'Lato';
                font-style: normal;
                font-weight: 400;
                font-size: 24px;
                line-height: 32px;
                text-align: center;
                letter-spacing: 0.05em;
                color: #000000;
                @include mobile {
                    width: 40px !important;
                    height: 48px; 
                    min-width: unset;
                }
                &[type=number]::-webkit-inner-spin-button, 
                &[type=number]::-webkit-outer-spin-button { 
                    -webkit-appearance: none;
                    -moz-appearance: none;
                    appearance: none;
                    margin: 0; 
                }
            }
        }
    }
}
