/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../../../src/style/abstract/variables';
@import '../../../../src/style/abstract/media';
@import '../../../../src/style/abstract/button';
@import '../../../../src/style/abstract/loader';
@import '../../../../src/style/abstract/icons';
@import '../../../../src/style/abstract/parts';

.OrboWrapper {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background: rgba(255,255,255, 0.6);
    left: 0 !important;
    top: 0 !important;
    display: none;
    z-index: 9999;
    @include mobile {
        width: 100vw;
        height: 100vh;
        background: rgba(241, 237, 237, 1);
    }
    &_isOpen {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        @include mobile {
            display: block;
        }
    }
    #output {
        @include mobile {
            width: 100vw !important;
            height: 100vh !important;
            object-fit: cover;
            object-position: center;
        }
    }
    &-Video {
        position: relative;
        @include mobile {
            width: 100vw;
            height: 100vh;
            overflow: hidden;
        }
    }
    &-Options {
        padding: 10px;
        width: 100%;
        padding-right: 0;
        .ProductConfigurableAttributes-ExpandableContentHeading, .ProductConfigurableAttributes-ExpandableContentSubHeading {
            color: var(--color-black);
        }
        .Field input {
            background-color: var(--color-black);
        }
        .ProductConfigurableAttributes-SwatchList {
            gap: 10px;
        }
        .ProductConfigurableAttributes-SelectedOptionLabel {
            display: none;
        }
        @include mobile {
            .ProductConfigurableAttributes-SwatchList {
                justify-content: flex-start;
            }
            .ProductAttributeValue-Color_isPDP {
                min-height: 35px;
                width: 35px !important;
            }
            .ProductAttributeValue-Color_isPDP::after {
                height: 10px !important;
                width: 17px !important;
                top: 10px !important;
                left: 8px !important;
            }
            .ProductConfigurableAttributes-ExpandableContentHeading {
                margin-top: 5px;
            }
        }
    }
    &-Header {
        display: flex;
        align-items: center;
        width: 100%;
        background: #DE4D47;
        padding: 10px;
        gap: 15px;
    }
    &-CloseButton {
        color: #ffffff;
        font-size: 22px;
        font-weight: 600;
        .CloseIcon {
            fill: #ffffff;
            cursor: pointer;
            width: 40px !important;
            height: 40px !important;
        }
    }
    &-ProductTitle {
        color: #ffffff;
        font-size: 18px;
        font-weight: 600;
        flex: 1;
        @include mobile {
            top: 0;
            position: relative;
        }
    }
    .ProductConfigurableAttributes {
        &-OptionLabel, &-SelectedOptionLabel {
            color: var(--color-white);
        }
        &-SwatchList {
            flex-direction: row;
        }
    }
    &-AddToCart {
        flex: 1;
        .TryNow-Wrapper {
            display: none;
        }
        .ProductActions-AddToCartFixed {
            position: initial;
            .Field-Wrapper {
                display: none;
            }
        }
        .ProductActions-AddToCart {
            flex: 1;
            width: 100% !important; 
            max-width: unset !important;
            height: 42px !important;
            @include mobile {
                height: 48px !important;
            }
        }
    }
    &-Actions {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        @include mobile {
            position: fixed;
        }
    }
    .ProductActions-AddToCartWrapper {
        margin-top: 0;
    }
    .ProductConfigurableAttributes-Title {
        margin-top: 0;
    }
    .ProductConfigurableAttributes-SwatchList {
        margin-bottom: 0;
    }
    &-Details {
        margin: 10px;
        margin-top: 20px;
        padding: 8px;
        border-radius: 5px;
        background-color: var(--color-white);
        &Top {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        &Bottom {
            display: flex;
            justify-content: stretch;
            margin-top: 15px;
        }
    }
    .ProductActions-Price {
        overflow: hidden;
    }
    &-SelectedVariation {
        font-size: 18px;
    }
}
