/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/* stylelint-disable*/ 
.SkinConcern {
    .Page-Heading {
        font-size: 5.4rem;
        text-align: center;
        margin-block: 50px 40px;
        @include mobile {
            font-size: 4.2rem;
        }
    }
    &-List {
        max-width: calc(var(--content-wrapper-width) - 80px);
        margin-inline: auto;
        display: flex;
        justify-content: center;
        margin-block-end: 70px;
        &:hover {
            .SkinConcern-Item:not(:hover) {
                @include desktop {
                    filter: blur(2px) opacity(.5);
                }
            }
        }
        &Wrapper {
            @include mobile {
                overflow-x: scroll;
            }
        }
        @include mobile {
            margin-bottom: 25px;
        }
    }
    &-Item {
        display: flex;
        flex-direction: column;
        align-items: center;
        color: var(--color-black);
        gap: 10px;
        transition: all 0.3s ease;
        flex: 0 0 134px;
        text-align: center;
        &:hover {
            color: var(--color-black);
        }
        img {
            width: 82px;
            height: 82px;
        }
        span {
            font-size: 14px ;
            font-weight: 300;
        }
    }
    &-Recommendations {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        max-width: calc(var(--content-wrapper-width) - 80px);
        margin-inline: auto;
        gap: 20px;
        margin-bottom: 50px;
        @include mobile {
            grid-template-columns: repeat(2, 1fr);
            gap: 0;
            margin-inline: 8px;
        }
    }
    &-Recommendation {
        padding: 8px;
        background: #efe5d6;
        @include mobile {
            box-shadow: inset -1px 1px #efdaca;
        }
        &-Image {
            display: block;
        }
        &-Title {
            text-align: center;
            font-size: 34px;
            max-width: 250px;
            margin: 20px auto 25px;
            @include mobile {
                font-size: 18px;
                margin: 17px auto 17px;
                font-weight: 700;
            }
        }
        &-Body {
            text-align: center;
            font-size: 16px;
            max-width: 250px;
            margin: 0px auto 14px;
            @include mobile {
                font-size: 13px;
            }
        }
        &-Cta {
            max-width: 250px;
            display: block;
            padding: 10px;
            border: 1px solid #d5c3b9;
            transition: .3s all;
            color: #000000BF;
            font-weight: 700;
            text-align: center;
            margin-inline: auto;
            margin-block-end: 35px;
            &:hover {
                color: var(--color-white);
                background: var(--color-black);
            }
            @include mobile {
                font-size: 13px;
                margin-block-end: 0;
            }
        }
    }
    &-Last {
        max-width: 726px;
        margin-inline: auto;
        padding: 10px 60px 70px;
        @include mobile {
            padding-inline: 23px;
        }
        &-Heading {
            font-size: 24px;
            text-align: center;
        }
        &-Body {
            font-size: 16px;
            text-align: center;
            line-height: 1.7;
        }
    }
}
