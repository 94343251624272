/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../../../../src/style/abstract/variables';
@import '../../../../../src/style/abstract/media';
@import '../../../../../src/style/abstract/button';
@import '../../../../../src/style/abstract/loader';
@import '../../../../../src/style/abstract/icons';
@import '../../../../../src/style/abstract/parts';


:root {
    --skincare-primary-color: #f14b4f;
    --color-white: #ffffff;
}
.SkinAnalyser {
    @include mobile {
        margin-top: 48px;
    }
    input[type="text"] {
        width: 100%;
        color: rgb(255, 255, 255);
        padding: 0px 0px 8px;
        border: none;
        outline: none;
        border-radius: 0px;
        appearance: none;
        transform: translateZ(0px);
        font-size: 30px;
        -webkit-font-smoothing: antialiased;
        -webkit-text-fill-color: rgba(255, 255, 255, 1);
        animation: 1ms ease 0s 1 normal none running native-autofill-in;
        transition: background-color 1e+08s ease 0s, box-shadow 0.1s ease-out 0s;
        box-shadow: rgba(255, 255, 255, 0.3) 0px 1px !important;
        background-color: transparent !important;
        &:focus {
            box-shadow: rgb(255, 255, 255) 0px 2px;
        }
        &::placeholder {
            -webkit-text-fill-color: rgba(255, 255, 255, 0.3);
        }
        @include mobile {
            font-size: 20px;
        }
    }
    input[type=radio] {
        appearance: auto;
        display: none;
        + span {
            display: flex;
            -webkit-box-align: center;
            align-items: center;
            border-radius: 4px;
            background-color: rgba(255, 255, 255, 0.1);
            box-shadow: rgba(255, 255, 255, 0.6) 0px 0px 0px 1px inset;
            color: rgb(255, 255, 255);
            max-width: 100%;
            min-width: 200px;
            min-height: 40px;
            outline: 0px;
            padding: 4px 9px;
            transition-duration: 0.1s;
            transition-property: background-color, color, border-color, opacity, box-shadow;
            transition-timing-function: ease-out;
            width: 100%;
            cursor: pointer;
            opacity: 1;
            gap: 10px;
            &:hover {
                background-color: rgba(255, 255, 255, 0.2);
            }
            >span:nth-child(1) {
                font-weight: 700;
                width: 24px;
                min-width: 22px;
                height: 24px;
                border-radius: 2px;
                font-size: 12px;
                line-height: 16px;
                font-family: sans-serif;
                border: 1px solid rgba(255, 255, 255, 0.6);
                background-color: rgb(213, 175, 47);
                color: rgb(255, 255, 255);
                display: flex;
                align-items: center;
                justify-content: center;
            }
            >span:nth-child(2) {
                font-weight: unset;
                font-size: 20px;
                line-height: 28px;
            }
            >span:nth-child(3) {
                position: absolute;
                right: 10px;
                top: 50%;
                transform: translateY(-50%);
            }
        }
        + .iconlabel {
            display: block;
            min-height: 335px;
            @include mobile {
                min-width: unset;
                min-height: 305px;
            }
            .iconlabel-text {
                font-size: 22px !important;
                font-weight: 400 !important;
                width: calc(100% - 20px) !important;
                height: 100px !important;
                margin-bottom: 20px;
                margin-top: 9px;
                margin-inline: 10px;
                box-sizing: border-box;
                border-radius: 20px !important;
                background: #ffffff !important;
                color: #000000 !important;
                text-transform: uppercase;
                @include mobile {
                    font-size: 20px !important;
                    width: 100% !important;
                    margin-bottom: 9px;
                    margin-top: 9px;
                    margin-inline: 0px;
                }
            }
            .iconlabel-image {
                min-height: 150px;
                width: unset;
                background: transparent;
                border: transparent;
                padding: 21px;
                @include mobile {
                    min-height: 130px;
                    padding: 10px;
                }
                img {
                    @include mobile {
                        height: 100%;
                    }
                }
            }
            .iconlabel-content {
                display: flex;
                color: white;
                outline: 0px;
                padding: 0;
                transition-duration: 0.1s;
                transition-property: background-color, color, border-color, opacity, box-shadow;
                transition-timing-function: ease-out;
                cursor: pointer;
                opacity: 1;
                gap: 10px;
                >span:nth-child(1) {
                    font-weight: 700;
                    width: 24px;
                    min-width: 22px;
                    height: 24px;
                    border-radius: 2px;
                    font-size: 12px;
                    line-height: 16px;
                    font-family: sans-serif;
                    border: 1px solid rgba(255, 255, 255, 0.6);
                    background-color: rgb(213, 175, 47);
                    color: rgb(255, 255, 255);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    @include mobile {
                        width: 19px;
                        min-width: 19px;
                        height: 19px;
                        border-radius: 2px;
                        font-size: 11px;
                        line-height: 14px;
                    }
                }
                >span:nth-child(2) {
                    font-weight: unset;
                    font-size: 16px;
                    line-height: 24px;
                    @include mobile {
                        font-size: 13px;
                        line-height: 20px;
                    }
                }
            }
        }
        &:checked + span {
            background-color: rgba(255, 255, 255, 0.2);
            box-shadow: rgba(255, 255, 255, 0.6) 0px 0px 0px 2px inset;
            >span:nth-child(1) {
                color: rgb(213, 175, 47);
                background-color: rgb(255, 255, 255);
                border: 1px solid rgba(255, 255, 255, 1);
            }
        }
        &:checked + .iconlabel{
            .iconlabel-image {
                background-color: transparent;
                border: transparent;
            }
            .iconlabel-tick {
                top: 0px;
                fill: #000000;
                transform: unset;
                border-bottom: solid 24px transparent;
                border-left: solid 24px transparent;
                border-right: solid 24px #ffffff;
                border-top: solid 24px #ffffff;
                right: 0px;
                svg {
                    background: #ffffff;
                    color: #000000;
                    position: absolute;
                    right: -18px;
                    top: -15px;
                }
            }
        }
    }
    button, a {
        font-weight: 700;
        cursor: pointer;
        transition-duration: 0.1s;
        transition-property: background-color, color, border-color, opacity, box-shadow;
        transition-timing-function: ease-out;
        border: 1px solid transparent;
        box-shadow: black 0px 3px 12px 0px;
        background-color: #fbce37;
        color: #322701;
        border-radius: 4px;
        display: inline-block;
        &.small {
            padding: 5px 15px;
            font-size: 20px;
            line-height: 28px;
            @include mobile {
                padding: 4px 10px;
                font-size: 14px;
                line-height: 25px;
            }
        }
        &.large {
            padding: 15px 18px;
            font-size: 24px;
            line-height: 32px;
            @include mobile {
                padding: 6px 13px;
                font-size: 18px;
                line-height: 25px;
            }
        }
    }
    &-Welcome {
        @include mobile {
            height: 100vh;
            display: flex;
            align-items: center;
            background: var(--skincare-primary-color);
        }
        &:hover {
            cursor: pointer;
        }
    }
    &-WelcomeContentWrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: var(--skincare-primary-color);
        padding: 80px;
        @include mobile {
            padding: 20px;
        }
    }
    &-WelcomeLogo {
        background: #f7f7f7;
        @include mobile {
            display: none;
        }
        img {
            max-height: 800px;
            width: 100%;
            object-fit: contain;
        }
    }
    &-WelcomeContentHeading {
        margin: 0px;
        width: inherit;
        font-weight: unset;
        font-size: 36px;
        line-height: 44px;
        color: rgb(0, 0, 0);
        margin-bottom: 27px;
        @include mobile {
            font-size: 25px;
            line-height: 32px;
            margin-bottom: 15px;
        }
    }
    &-WelcomeContentText {
        margin: 0px;
        max-width: 100%;
        width: inherit;
        font-weight: unset;
        font-size: 20px;
        line-height: 28px;
        color: rgba(0, 0, 0, 0.7);
        margin-bottom: 38px;
        @include mobile {
            font-size: 17px;
            line-height: 23px;
            margin-bottom: 24px;
        }
    }
    &-WelcomeContentLink {
        margin-bottom: 15px;
    }
    &-WelcomeContentTimeLeft {
        font-size: 14px;
        line-height: 20px;
        color: rgb(0, 0, 0);
        @include mobile {
            font-size: 12px;
            line-height: 18px;
        }
    }
    &-CustomerNameWrapper {
        background: var(--skincare-primary-color);
        min-height: 800px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-block: 50px;
        @include mobile {
            min-height: 100vh;
        }
    }
    &-CustomerNameContent {
        display: flex;
        align-items: flex-start;
        gap: 15px;
        min-width: 600px;
        max-width: 800px;
        @include mobile {
            min-width: unset;
            flex-direction: column;
            width: 90%;
            gap: 10px;
        }
    }
    &-CustomerNameStepCount {
        color: var(--color-white);
        display: flex;
        gap: 4px;
        align-items: center;
        > div:nth-child(1) {
            font-weight: unset;
            font-size: 16px;
            line-height: 24px;
        }
    }
    &-CustomerNameHeading {
        font-weight: unset;
        font-size: 24px;
        line-height: 32px;
        margin-bottom: 11px;
        @include mobile {
            font-size: 18px;
            line-height: 23px;
            margin-bottom: 9px;
        }
    }
    &-CustomerNamePara {
        font-weight: unset;
        font-size: 20px;
        line-height: 28px;
        color: rgba(0, 0, 0, 0.7);
        margin-bottom: 14px;
        @include mobile {
            font-size: 16px;
            line-height: 20px;
            color: rgba(0, 0, 0, 0.7);
            margin-bottom: 6px;
        }
    }
    &-CustomerNameForm {
        &.iconform {
            >div {
                display: grid;
                grid-template-columns: repeat(4, 1fr);
                gap: 10px;
                @include mobile {
                    grid-template-columns: repeat(2, 1fr);
                }
                label {
                    width: 25%;
                    @include mobile {
                        max-width: unset;
                        min-width: unset;
                        width: unset;
                    }
                }
            }
        }
        &.skin_concern_form {
            >div {
                display: grid;
                grid-template-columns: repeat(4, 1fr);
                gap: 10px;
                margin-bottom: 20px;
                @include mobile {
                    grid-template-columns: repeat(2, 1fr);
                }
                label {
                    width: 25%;
                    margin-bottom: 0;
                    @include mobile {
                        max-width: unset;
                        min-width: unset;
                        width: unset;
                    }
                    .iconlabel {
                        min-height: 210px;
                        @include mobile {
                            min-height: 183px;
                        }
                    }
                    .iconlabel-image {
                        img {
                            object-fit: contain;
                            height: 100%;
                        } 
                    }
                }
            }
        }
        &.skin_goal_form {
            >div {
                display: grid;
                grid-template-columns: repeat(4, 1fr);
                gap: 10px;
                margin-bottom: 20px;
                @include mobile {
                    grid-template-columns: repeat(2, 1fr);
                }
                label {
                    width: 25%;
                    margin-bottom: 0;
                    @include mobile {
                        max-width: unset;
                        min-width: unset;
                        width: unset;
                    }
                    .iconlabel {
                        min-height: 210px;
                        @include mobile {
                            min-height: 185px;
                        }
                    }
                    .iconlabel-image {
                        img {
                            object-fit: contain;
                            height: 100%;
                        } 
                    }
                }
            }
        }
        input, label {
            margin-bottom: 15px;
        }

        label {
            max-width: 400px;
            min-width: 200px;
            width: max-content;
        }
        button {
            margin-right: 20px;
        }
    }
    &-ThankYou {
        background-color: var(--skincare-primary-color);
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 800px;
        @include mobile {
            min-height: 100vh;
        }
        &Image {
            text-align: center;
            margin-bottom: 50px;
            @include mobile {
                margin-bottom: 16px;
            }
            img {
                width: 120px;
                height: 120px;
            }
        }
        &Text {
            text-align: center;
            margin-bottom: 30px;
            font-weight: unset;
            font-size: 36px;
            line-height: 44px;
            @include mobile {
                margin-bottom: 20px;
                font-size: 20px;
                line-height: 30px;
            }
        }
        &Link {
            text-align: center;
            a {
                font-weight: unset;
                font-size: 24px;
                line-height: 32px;
                padding: 10px 24px;
                font-size: 20px;
                line-height: 24px;
                padding: 8px 18px;
            }
        }
        &Inner {
            a {
                background: transparent;
                box-shadow: unset;
            }
        }
    }
    .EmailForm {
        &-CountryInput {
            display: flex;
            align-items: center; 
            margin-bottom: 15px;
            gap: 11px;
            input {
                margin-bottom: 0;
            }
        }
        &-CountryCode {
            select {
                border: none;
                appearance: none !important;
                box-shadow: rgba(255, 255, 255, 0.3) 0px 1px;
                font-size: 30px;
                padding: 4px;
                color: var(--color-white);
                background: transparent;
                option {
                    color: #000000;
                    padding-inline: 5px;
                    font-size: 20px;
                }
            }
        }
    }
    .SkinTypeForm {
        label {
            @include mobile {
                max-width: 160px !important;
            }
        }
        .iconlabel {
            @include mobile {
                min-height: 100px !important;
            }
            &-content {
                @include mobile {
                    display: none !important;
                }
            }
        }
    }
}
