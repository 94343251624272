/* stylelint-disable */
.accordion {
    overflow: hidden;
    &-item {
        width: 100%;
        overflow: hidden;
        border-bottom: 1px solid #D9D9D9;
        margin-bottom: 34px;
        padding-bottom: 24px;
        &:last-child {
            border-bottom: 1px solid transparent;
        }
    }
    &-label {
        display: flex;
        justify-content: space-between;
        font-family: 'Lato';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 21px;
        cursor: pointer;
        /* Icon */
        &::after {
            // content: "\276F";
            content: "";
            background-image: url(https://www.zaynmyza.com/media/wysiwyg/accordion_arrow.png);
            background-size: contain;
            width: 24px;
            height: 24px;
            text-align: center;
            transition: all .35s;
            background-repeat: no-repeat;
            background-position: center;
        }
    }
    &-content {
        max-height: 0;
        padding: 0;
        transition: all .35s;
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: #818284;
        overflow: hidden;
    }
    &-close {
        display: flex;
        justify-content: flex-end;
        padding: 1em 0;
        font-size: 0.75em;
        cursor: pointer;
    }
    input {
        position: absolute;
        opacity: 0;
        z-index: -1;
    }
    // :checked
    input:checked {
    + .accordion-label {
        &::after {
            transform: rotate(-180deg);
        }
    }
    ~ .accordion-content {
        max-height: 100vh;
        padding: 10px 0;
    }
    }
  }
  