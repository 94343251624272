/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.ContactPage {
    &-Heading {
        height: 36px;
        margin-block-start: 24px;
        margin-block-end: 18px;
        font-size: 24px;
    }

    &-ColumnWrapper {
        display: flex;
        margin-block-end: 24px;

        @include mobile {
            display: block;
            padding: 12px 0;
        }
    }

    &-Column {
        flex: 1;

        &_isContent {
            margin-inline-end: 18px;

            @include mobile {
                margin-inline-end: 0;
            }
        }
    }

    .Form {
        .Button {
            @include mobile {
                width: 100%;
            }
        }
    }
}
