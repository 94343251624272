/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/* stylelint-disable */
.CrossSellCarousel {
    .ProductCard {
        // width: calc(100% - 10px);
        //margin-inline-end: 10px;
        margin: auto;
    }
    .slick-slide {
        float: right;
    }
    .slick-next {
        right: 10px;
    }
    .slick-prev {
        left: 10px;
    }
    .slick-prev, .slick-next {
        z-index: 1;
    }
    .slick-prev:before, .slick-next:before {
        color: var(--color-black)
    }
}
