/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

.CookiePopup {
    --cookie-popup-bottom-margin: 10px;

    background-color: black;
    position: fixed;
    inset-block-end: var(--cookie-popup-bottom-margin);
    display: flex;
    opacity: 0.9;
    align-items: center;
    width: fit-content;
    z-index: 200;

    @include desktop {
        inset-inline-start: 50%;
        transform: translateX(-50%);
        padding: 5px 10px;

        &:dir(rtl) {
            transform: translateX(50%);
        }
    }

    @include mobile {
        inset-block-end: calc(var(--footer-total-height) + var(--cookie-popup-bottom-margin));
        flex-direction: column;
        width: calc(100% - 32px);
        margin-inline: 16px;
        padding: 8px 16px;
    }

    &-Content {
        color: white;
        font-size: 12px;
        font-weight: 700;
        margin-block-end: 0;
        text-align: center;

        &::after {
            content: '.';
            color: var(--color-white);
        }
    }

    &-Link {
        font-size: 12px;
    }

    &-CTA {
        color: var(--primary-base-color);
        text-transform: uppercase;
        font-weight: 700;
        font-size: 14px;
        cursor: pointer;
        white-space: nowrap;

        @include desktop {
            margin-inline-start: 41px;
        }

        @include mobile {
            margin-block-start: 12px;
        }

        &:hover {
            color: var(--primary-dark-color);
        }
    }
}

