/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/* stylelint-disable */

:root {
    --menu-item-height: 20px;
    --menu-total-height: 5rem;
}

@mixin subcategory-visible {
    pointer-events: all;
    z-index: 10;
    opacity: 1;
    transform: translateX(0);
}
.Menu-SubMenu_isVisible {
    @include mobile {
        padding: 0 !important;
    }
}

.Menu {
    &-TopOverlay {
        @include before-desktop {
            background-color: var(--menu-desktop-background-color);
        }
        height: var(--header-top-menu-height);
        width: 100%;
        position: fixed;
        z-index: -1;
        top: 0px;
        left: 0;
        opacity: 0;
        transition: opacity 200ms ease-in;
        will-change: transition;

        &_isVisible {
            opacity: 1;
        }
    }
    &-Overlay {
        @include before-desktop {
            background-color: var(--menu-desktop-background-color);
        }
        height: 100vh;
        width: 100%;
        position: absolute;
        z-index: -1;
        top: 3px;
        left: 0;
        opacity: 0;
        transition: opacity 200ms ease-in;
        will-change: transition;

        &_isVisible {
            opacity: 1;
        }
    }
    &-CompareLink {
        color: var(--color-black);
        font-size: 14px;
        font-weight: 400;
        text-transform: none;

        display: flex;
        align-items: center;

        .CompareIcon {
            margin-inline-end: 12px;

            &:hover {
                fill: var(--color-black);
            }
        }

        &Wrapper {
            border-block-end: 1px solid var(--primary-divider-color);
            padding-block-start: 17px;
            padding-block-end: 16px;
            padding-inline: 0;
            margin: 0 16px;

            @include mobile {
                padding: 13px 0;
                display: none;
            }
        }
    }

    &-ExpandedState {
        --plus-minus-icon-size: 18px;

        display: inline-block;
        float: inline-end;

        &:dir(rtl) {
            float: inline-start;
        }

        position: absolute;
        inset-inline-end: 15px;

        @include desktop {
            display: none;
        }
    }

    &-Item {
        padding-inline-start: 0;
        border-bottom: 1px solid #e0e0e0;
        margin: 0;
        @include desktop {
            margin: 0;
            height: 100%;
            border-bottom: 1px solid transparent;
        }

        &::before {
            content: none;
        }

        &Caption {
            color: var(--color-black);

            &:hover {
                color: var(--link-hover);
            }

            @include mobile {
                padding: 16px;
                border-bottom: 1px solid #e0e0e0;
            }

            &_isExpanded {
                background: var(--link-hover);
                color: white;
                @include mobile {
                    &:hover {
                        color: #fff;
                    }
                    .MinusIcon {
                        fill: #fff;
                    }
                }
            }

            &_isSecondLevel {
                font-weight: 700;
            }

            &_type {
                &_main {
                    font-family: 'Poppins';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 16px;
                    color: #161616;
                }

                &_subcategory {
                    @include mobile {
                        margin-inline-end: 0;
                    }

                    @include desktop {
                        margin-block-start: 5px;
                        display: inline-block;
                    }
                }
            }
        }

        &List {
            @include desktop {
                display: flex;
                height: 100%;
                justify-content: space-around;

                .Menu-SubMenu & {
                    display: block;
                }
            }

            &_type_subcategory {
                @include mobile {
                    padding-block: 8px;
                }
            }
        }

        &:not(:first-of-type) {
            @include desktop {
                margin-inline-start: 20px;
            }
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    &-Link {
        display: contents;
        font-weight: 400;
        height: 100%;
        display: flex;
        align-items: center;
        text-decoration: none !important;
        &:hover {
            .Menu-ItemCaption_type_main {
                color: var(--link-hover);
            }
        }
    }

    &-MainCategories {
        margin: 0 auto;
        height: 100%;

        @include desktop {
            width: 100%;
            max-width: var(--content-wrapper-width);
            padding: 0 20px;

            .Menu-Link {
                font-weight: 700;
            }
        }
    }

    &-MenuWrapper {
        @include desktop {
            height: 100%;
            padding: 0px;
            width: 100%;
            border-top: 1px solid #e0e0e0;
            border-bottom: 1px solid #e0e0e0;
        }
    }

    &-SubCategories {
        @include desktop {
            padding-inline: 1rem;
            padding-block-end: 8px;
        }

        &Wrapper {
            position: fixed;
            top: calc(var(--header-height) - 2px) !important;
            left: 0px;
            z-index: 1;
            background-color: #ffffff;
            width: 100%;
            @include desktop {
                margin-inline: auto;
            }
            .Menu-Link {
                height: fit-content;
                font-size: 13px;
                line-height: 17px;
                font-weight: 400;
                figcaption {
                    color: rgba(3, 2, 26, 0.5);
                }
            }
            &Inner {
                margin-inline: auto;
                width: 100%;
                max-width: var(--content-wrapper-width);
            }
        }
    }

    &-ItemCaption:hover {
        color: var(--link-hover) !important;
    }

    &-Sub {
        &ItemWrapper {
            @include desktop{
                // flex: 0 0 20%;
                // page-break-inside: avoid; 
                break-inside: avoid;
                padding: 0;
            }
            > .Menu-Link {
                @include desktop {
                    font-weight: 600;
                    text-transform: uppercase;
                    font-size: 14px;

                    figcaption {
                        color: rgba(3, 2, 26, 0.8);
                        font-weight: 600;
                        color: #000;
                    }
                }
            }
            padding: 1rem;
            @include mobile {
                .Menu-ItemCaption {
                    border: none;
                }
            }
        }

        &Menu {
            display: none;

            &_isVisible {
                display: grid !important;
                grid-template-columns: auto 200px !important;
                background: var(--color-gray);
                display: block;
                padding-inline: 16px;
            }

            @include desktop {
                background: none;
                display: block;
                padding: 0;
            }

            .Menu-SubMenu & {
                padding-block-end: 0;
            }

            .Menu-ItemCaption {
                @include mobile {
                    padding-block: 12px;
                    font-weight: 300;
                    text-transform: uppercase;
                }

                @include desktop {
                    padding-block-end: 8px;
                }
            }

            > .Menu-ItemList {
                @include desktop {
                    min-height: 340px;
                    display: block;
                    column-count: 4;
                    padding: 20px;
                }
            }
        }
    }
    &-Creative {
        padding-block: 20px;
        padding-inline-end: 20px;
        img {
            object-fit: fill;
        }
    }
    &-ItemCaption {
        text-transform: capitalize;
        &_type_main {
            text-transform: uppercase;
        }
        &_type_subcategory {
            text-transform: uppercase;
        }
    }
}

.SubMenuOuter {
    > div:nth-child(1) {
        order: 99;
    }
}
