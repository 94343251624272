/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/* stylelint-disable */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */
.slick {
    &-arrow {
        width: 40px;
        height: 40px;
        z-index: 12;
    }
    &-prev {
        left: 0;
    &::before {
        font-size: 40px;
        opacity: 1;
        color: black;
        background: transparent;
    }
    }
    &-next {
        right: 0;
        &::before {
            font-size: 40px;
            opacity: 1;
            color: black;
            background: transparent;
        }
    }
}
.show-mobile {
    @include mobile {
        display: block !important;
    }
    @include desktop {
        display: none;
    }
}
ul {
    list-style: none;
    padding: 0px;
    margin: 0px;
}
li::before {
    content: none!important;
}
li:last-child {
    margin-bottom: 0px;
}
.HomePage {
    * {
        -ms-overflow-style: none;
        /* for Internet Explorer, Edge */
        scrollbar-width: none;
        /* for Firefox */
    }
    *::-webkit-scrollbar {
        display: none;
    /* for Chrome, Safari, and Opera */
    }

    @include mobile {
        .SearchField{
            margin: 0%;
            &-Wrapper{
                padding: 16px;
                padding-top: 10px;
                border-radius: 0;
                background-color: white;
            }
            &-SearchInnerWrapper{
                display: flex;
                flex-direction: row-reverse;
                align-items: center;
                border-radius: 80px;
                background-color: #F0F0F0;
                // margin-left: 18px;
                overflow: hidden;
            }
            &-Input {
                padding: 0%;
                padding-left: 20px;
                width: 100%;
                border: none;
                background-color: #F0F0F0;
                font-family: 'Lato';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 16px;
                color: black;
            }
            &-CloseIcon {
                position: relative;
                height: 24px;
                top: calc(50% - 12px);
                width: 24px;
                left: 10px;
            }
            &-SearchIcon {
                position: relative;
                height: 24px;
                top: calc(50% - 12px);
                width: 24px;
                left: 10px;
            }
        }
    }
    .category-slider {
        background-color: white;
        padding-block-end: 8px;
        min-height: 90px;
        padding-inline-start: 5%;
        ul {
            display: flex;
            overflow: scroll;
            column-gap: 8px;
            a {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                height: 94px;
                width: 69px;
                align-items: center;
                picture {
                    height: 69px;
                    width: 69px;
                    img {
                        height: 69px;
                        width: 69px;
                        border-radius: 50%;
                    }
                }
                span{
                    font-family: 'Lato';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 16px;
                    color: #000000;
                }
            }
        }
    }
    .slick-dots li button::before {
        content: none;
    }
    .banner-slider{
        width: 100%;
        @include desktop {
            min-height: 250px;
            .slick-list {
                min-height: 250px;
            }
        }
        @include mobile {
            min-height: 200px;
            .slick-list {
                min-height: 180px;
            }
        }
        .slick-slide img {
            max-height: 357px;
        }
        &-mobile {
            width: 100%;
            @include mobile {
                display: flex;
                align-items: center;
                min-height: 221px;
            }
        }
        &-mobile-short {
            padding-left: 5%;
            width: 100%;
            @include mobile {
                display: flex;
                align-items: center;
                min-height: 221px;
                .slick-slider {
                    display: flex;
                    flex-direction: column;
                    row-gap: 12px;
                    img {
                        min-height: 160px;
                        object-fit: cover;
                    }
                }
            }
        }
    }
    .productImg{
        &-Container {
            min-height: 450px;
            padding-top: 50px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            @include mobile {
                min-height: 345px;
                padding-top: 30px;               
            }
        }
        &-Wrap{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding-block: 20px;
            width: 100%;
            @include desktop {
                padding-inline: 5%;
                min-height: 285px;
            }
            @include mobile {
                padding-inline-start: 5%;
                min-height: 196px;
                margin-top: 10px;
                display: flex;
                ul {
                    display: flex;
                    flex-direction: row;
                    overflow: scroll;
                    li {
                        margin-left: 10px;
                        img {
                            width: 242px;
                            height: 160px;
                            object-fit: cover;
                        }
                    }
                }
            }
        }
        &-title {
            height: 43px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 30px;
            @include mobile {
                margin-bottom: 20px;
            }
            span {
                background-image: url('https://www.zaynmyza.com/media/new_zm/image_28.png');
                background-repeat: no-repeat;
                background-size: 100% 100%;
                line-height: 43px;
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 500;
                text-align: center;
                padding-inline: 20px;
                color: #161616;
                @include desktop {
                    font-size: 32px;
                }
                @include mobile {
                    font-size: 24px;
                    font-weight: 600;
                }
            }
        }
        &-Img {
            width: 48%;
            object-fit: contain;
        }
    }
    .HalalProducts {
        background: #edfaff;
        justify-content: space-between;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-block: 50px;
        @include mobile {
            padding-block: 30px;
        }
        &-title {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 800;
            font-size: 35px;
            line-height: 48px;
            text-align: center;
            color: #000000;
            text-transform: uppercase;
            letter-spacing: 3px;
            margin-bottom: 30px;
            font-style: italic;
            @include mobile {
                margin-bottom: 40px;
                font-weight: 800;
                font-size: 25px;
                line-height: 27px;
                letter-spacing: 1px;
            }
            span {
                font-weight: 400;
            }
        }
        &-img{
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            margin-top: 60px;
            @include mobile {
                justify-content: space-evenly;
                padding-inline: 4%;
                margin-top: 34px;
                row-gap: 10px;
                padding-top: 10px;
            }
            @include desktop {
                gap: 33px;
            }
        }
        &-Wrap{
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 132px;
            img {
                width: auto;
                height: 90px;
            }
            @include mobile {
                width: 144px;
                img {
                    width: auto;
                    height: 65px;
                }
            }
        }
        &-Text{
            //background-color: white;
            padding: 8px 0px;
            width: 100%;
            text-align: center;
            font-weight: 900;
            //top: -28px;
            //box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            span{
                font-family: 'Agrandir';
                font-style: normal;
                font-weight: 600;
                font-size: 20px;
                line-height: 26px;
                text-align: center;
                color: #000000;
            }
            @include mobile {
                padding: 5px 0px;
                text-align: center;
                span {
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 16px;
                }
            }
        }
        &-border {
            border: 1.5px solid black;
        }
        &-link{
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 20px;
            @include mobile {
                margin-top: 10px;
                width: 100%;
                padding-inline: 4%;
            }
            &-text {
                font-family: 'Lato';
                font-style: normal;
                font-weight: 300;
                font-size: 20px;
                line-height: 32px;
                text-align: center;            
                color: #000000;
                letter-spacing: 1px;
                @include mobile{
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 24px;
                    padding: 0 3%;
                }
            }
            &-button {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                border-radius: 6px;
                padding: 2px;
                margin-top: 24px;
                border: 1px solid black;
                span {
                    background: #000;
                    padding: 8px 16px;
                    border-radius: 6px;
                    font-family: 'Lato';
                    font-style: normal;
                    font-weight: 800;
                    font-size: 18px;
                    line-height: 19px;
                    color: #fff;
                    text-transform: uppercase;
                }
                @include mobile {
                    margin-top: 10px;
                    span {
                        font-size: 14px;
                    }
                }
            }
        }
    }
    .TrendingSlider{
        padding-inline: 5%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 50px 5% 50px;
        background-color: white;
        @include mobile {
            padding-block: 30px;
        }
        &-title {
            height: 43px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 30px;
            @include mobile {
                margin-bottom: 20px;
            }
            span {
                background-image: url('https://www.zaynmyza.com/media/new_zm/image_28.png');
                background-repeat: no-repeat;
                background-size: 100% 100%;
                line-height: 43px;
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 500;
                text-align: center;
                padding-inline: 20px;
                color: #161616;
                @include desktop {
                    font-size: 32px;
                }
                @include mobile {
                    font-size: 24px;
                    font-weight: 600;
                }

            }
        }
        &-slides {
            @include desktop {
                // padding-inline: 5%;
                .slick-dots {
                    position: relative;
                    bottom: 0px !important;
                    margin-top: 10px;
                }
            }
            .slick-slide{
                div {
                    display: flex;
                    justify-content: center;
                }
            }
            .slick-arrow svg {
                display: none;
            }
        }
        &-Wrap{
            display: flex!important;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            width: 207px;
            height: 312px;
            img {
                width: 207px;
                height: 207px;
                border-radius: 50%;
            }
            @include mobile {
                width: 144px!important;
                height: 201px;
                margin-bottom: 24px;
                img {
                    width: 144px;
                    height: 144px;
                    border-radius: 50%;
                }
            }
        }
        &-Content{
            display: flex;
            flex-direction: column;
            align-items: center;
            &-Name{
                font-family: 'Lato';
                font-style: normal;
                font-weight: 600;
                font-size: 32px;
                line-height: 36px;
                text-align: center;
                color: #161616;
                @include mobile {
                    font-size: 18px;
                    line-height: 21px;
                }
            }
            &-Content{
                font-family: 'Lato';
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 18px;
                text-align: center;
                color: #000000;
                background-color: #93F2FD;
                padding: 10px;
                margin-top: 12px;
                @include mobile {
                    padding: 4px 10px;
                    font-size: 12px;
                    line-height: 14px;
                    margin-top: 4px;
                }
            }
        }
    }
    .ShopBy{
        // background-image: url('https://www.zaynmyza.com/media/new_zm/Rectangle-79.webp');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        @include desktop {
            // min-height: 573px;
            padding-block: 50px;
        }
        @include mobile {
            // min-height: 366px;
            padding-block: 30px;
            // row-gap: 26px;
        }
        &-title {
            height: 43px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 30px;
            @include mobile {
                margin-bottom: 20px;
            }
            span {
                background-image: url('https://www.zaynmyza.com/media/new_zm/image_28.png');
                background-repeat: no-repeat;
                background-size: 100% 100%;
                line-height: 43px;
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 500;
                text-align: center;
                padding-inline: 20px;
                color: #161616;
                @include desktop {
                    font-size: 32px;
                }
                @include mobile {
                    font-size: 24px;
                    font-weight: 600;
                }

            }
        }
        .slick-initialized .slick-slide {
            @include mobile {
                margin-right: 8px;
            }
        }
        .slick {
            &-slide{
                width: 232px;
                @include mobile {
                    width: 170px;
                }
            }
            &-track {
                max-height: 330px;
                overflow: hidden;
                @include mobile {
                    display: block;
                }
            }
        }
        &-slides{
            width: 100%;
            max-width: 95%;
            margin-inline: auto 0%;
            @include mobile {
                max-height: 240px;
                overflow: hidden;
            }
            .slick-slider, .slick-initialized {
                width: 100%;
            }
            ul {
                display: flex;
                flex-direction: row;
                overflow: scroll;
            }
        }
        &-Wrap{
            max-width: 222px;
            max-height: 320px;
            overflow: hidden;
            border-radius: 10px;
            padding-inline: 4px;
            img{
                width: 100%;
                height: 100%;
            }
            @include mobile {
                border-radius: 8px;
                // max-width: 154px;
                max-height: 240px;
                img{
                    width: 100%;
                    height: 100%;
                    border-radius: 15px;
                }
            }
        }
        &-Content{
            display: none;
            flex-direction: column;
            align-items: center;
            position: absolute;
            bottom: 16px;
            width: 100%;
            &-Name{
                font-family: 'Montserrat';
                font-style: normal;
                font-weight: 700;
                font-size: 24px;
                line-height: 29px;
                text-align: center;
                color: #FFFFFF;
                @include mobile {
                    font-size: 14px;
                    line-height: 17px;
                }
            }
            &-Content{
                font-family: 'Montserrat';
                font-style: normal;
                font-weight: 600;
                font-size: 10px;
                line-height: 12px;
                text-align: center;
                font-size: 14px;
                line-height: 17px;
                background-color: #CEBC89;
                padding: 4px 8px;
                margin-block-start: 4px;
                margin-block-end: 11px;
                @include mobile {
                    font-size: 10px;
                    line-height: 12px;
                }
            }
            &-Button{
                &:hover{
                    color: #FFFFFF;
                    // outline: 1px solid #b53c37;
                    box-shadow: 3px 3px 8px black;
                }
                font-family: 'Montserrat';
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                line-height: 15px;
                letter-spacing: 0.05em;
                color: #FFFFFF;
                border: 1px solid white;
                padding: 8px 29px;
                @include mobile {
                    padding: 6px 14px;
                    font-size: 10px;
                    line-height: 12px;             
                }
            }
        }
    }
    .YouMe {
        height: 636px;
        @include mobile {
            height: 380px;
            padding-top: 30px;
            padding-bottom: 30px;
            justify-content: space-between;
        }
        display: flex;
        flex-direction: column;
        padding-inline-start: 5%;
        padding-block: 40px;
        @include mobile {
            padding-block: 30px;
        }
        &-title {
            background-image: url('https://www.zaynmyza.com/media/new_zm/image_28.png');
            background-repeat: no-repeat;
            background-size: 100% 100%;
            height: 43px;
            display: flex;
            align-items: center;
            justify-content: center;
            align-self: center;
            margin-bottom: 30px;
            @include mobile {
                margin-bottom: 20px;
            }
            span {
                line-height: 43px;
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 500;
                text-align: center;
                padding-inline: 20px;
                color: #161616;
                @include desktop {
                    font-size: 32px;
                }
                @include mobile {
                    font-size: 24px;
                    font-weight: 600;
                }

            }
        }
        &-slides{
            margin-top: 56px;
            display: flex;
            @include mobile {
                margin-top: 0px;
            }
            ul {
                display: flex;
                flex-direction: row;
                overflow: scroll;
                li {
                    margin-left: 10px;
                    @include desktop {
                        img {
                            width: 411px;
                        }
                    }
                    @include mobile {
                        margin-bottom: 0;
                        height: fit-content;
                        img {
                            width: 242px;
                        }
                    }
                }
            }
        }
    }
    .ZMIconic {
        display: flex;
        flex-direction: column;
        margin: auto;
        padding-block: 50px;
        background-color: white;
        @include mobile {
            padding-block: 30px;
        }
        & > section {
            max-width: var(--content-wrapper-width);
            padding-left: 0px;
        }
        &-title {
            background-image: url('https://www.zaynmyza.com/media/new_zm/image_28.png');
            background-repeat: no-repeat;
            background-size: 100% 100%;
            height: 43px;
            display: flex;
            align-items: center;
            justify-content: center;
            align-self: center;
            margin-bottom: 30px;
            @include mobile {
                margin-bottom: 20px;
            }
            span {
                line-height: 43px;
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 500;
                text-align: center;
                padding-inline: 20px;
                color: #161616;
                @include desktop {
                    font-size: 32px;
                }
                @include mobile {
                    font-size: 24px;
                    font-weight: 600;
                }
            }
        }
        &-slides{
            // padding-inline-start: 5%;
            display: flex;
            flex-direction: row;
            overflow: scroll;
            @include desktop {
                margin-inline: auto;
            }
            ul {
                display: flex;
                flex-direction: row;
                overflow: scroll;
                width: 100%;
                column-gap: 10px;
                li {
                    &:first-child {
                        margin-left: auto;
                    }
                    &:last-child {
                        margin-right: auto;
                    }
                    @include desktop {
                        img {
                            width: 411px;
                        }
                    }
                    @include mobile {
                        img {
                            width: 242px;
                        }
                    }
                }
            }
        }
    }
    .ZMBlog {
        //max-width: var(--content-wrapper-width);
        display: flex;
        flex-direction: column;
        padding-block: 50px;
        @include mobile {
            padding-block: 30px;
        }
    
        &-title {
            height: 43px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 30px;
            @include mobile {
                margin-bottom: 20px;
            }
            span {
                background-image: url('https://www.zaynmyza.com/media/new_zm/image_28.png');
                background-repeat: no-repeat;
                background-size: 100% 100%;
                line-height: 43px;
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 500;
                text-align: center;
                padding-inline: 20px;
                color: #161616;
                @include desktop {
                    font-size: 32px;
                }
                @include mobile {
                    font-size: 24px;
                    font-weight: 600;
                }

            }
        }
    
        &-slides {
            max-width: 95%;
            margin-inline: auto 0%;
            //overflow: scroll;
            .slick-slider, .slick-initialized {
                width: 100%;
                max-height: 370px;
                overflow: hidden;
                @include mobile {
                    max-height: 160px;
                }
            }
            
            .slick {
                &-slide{
                    width: 372px;
                    @include mobile {
                        width: 180px;
                    }
                }
                &-track {
                    display: flex;
                    flex-direction: row;
                    //overflow: scroll;
                    column-gap: 24px;
                    
                    @include mobile {
                        column-gap: 8px;
                    }
                }
            }
        }
    
        &-Container {
            display: flex!important;
            flex-direction: column;
            align-items: center;
            padding: 0px;
            row-gap: 24px;
            width: 100% !important;
    
            @include mobile {
                width: 100% !important;
                row-gap: 8px;
            }
        }
        &-Image {
            width: 100%;
            max-height: 230px;
            overflow: hidden;
    
            @include mobile {
                width: 100%;
                height: 104px;
            }
        }
        &-Content {
            display: flex;
            flex-direction: column;
            row-gap: 16px;
            @include mobile {
                row-gap: 8px;
            }
        }
        &-Title {
            height: 56px;
            overflow: hidden;
            letter-spacing: 0.05em;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            color: #000000;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            max-height: 4.2rem;
            @include mobile {
                height: 64px;
                font-size: 12px;
                line-height: 16px;
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    max-height: 3.2rem;
            }
        }
        &-Description {
            height: 48px;
            overflow: hidden;
            font-family: 'Lato';
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            color: #464646;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            max-height: 4.2rem;
            @include mobile {
                display: none;
                height: unset !important;
                font-size: 14px;
                line-height: 14px;
            }
        }
    }
    .ZMLove {
        background-color: #FFEAF0;
        padding-inline-start: 5%;
        padding-block: 50px;
        @include desktop {
            display: grid;
            grid-template-columns: 22% 78%;
            &svg {
                position: absolute;
                fill: #FFEAF0;
                filter: brightness(0.9);
                width: 100%;
                height: 300px;
                left: -15px;
                top: -50px;
            }
        }
        @include mobile {
            &svg {
                position: absolute;
                fill: #FFEAF0;
                filter: brightness(0.9);
                width: 100%;
                height: 300px;
                left: 0px;
                top: -21px;
            }
            padding-block: 30px;
        }
        &-Wrap{
            width: 302px;
            height: 157px;
            display: flex;
            flex-direction: column;
            row-gap: 40px;
            &-Title{
                font-family: 'Lato';
                font-style: normal;
                font-weight: 600;
                font-size: 40px;
                line-height: 46px;
                color: #000000;
            }
            &-Description{
                font-family: 'Lato';
                font-style: normal;
                font-weight: 300;
                font-size: 16px;
                line-height: 24px;
                color: #393939;
            }
        }

        &-title {
            height: 43px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 30px;
            @include mobile {
                margin-bottom: 20px;
            }
            span {
                //background-image: url('https://www.zaynmyza.com/media/new_zm/image_28.png');
                //background-repeat: no-repeat;
                //background-size: 100% 100%;
                line-height: 43px;
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 500;
                text-align: center;
                padding-inline: 20px;
                color: #161616;

                @include desktop {
                    font-size: 32px;
                }

                @include mobile {
                    font-size: 24px;
                    font-weight: 600;
                }

            }

        }

        .slick-slide:first-child {
            display: none !important;
        }

        &-slides {
            display: flex;
            flex-direction: row;
            overflow: scroll;
            ul {
                display: flex;
                flex-direction: row;
                overflow: scroll;
                
                li {
                    margin-inline-start: 10px;
                }
            }
        }

        &-Container {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 0px;
            gap: 24px;
            @include desktop {
                width: 411px;
                height: 552px;
            }

            @include mobile {
                width: 242px;
                height: 390px;
            }
        }
        &-Image {
            @include desktop {
                width: 411px;
            }

            @include mobile {
                width: 242px;
            }
        }
        &-Content {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            svg {
                position: absolute;
                filter: brightness(0.8);
            }
            @include desktop {
                padding: 30px;
                width: 360px;
                height: 360px;
            }

            @include mobile {
                padding: 20px 15px;
                width: 330px;
                height: 280px;
            }
        }
        &-description {
            height: 183px;
            overflow: hidden;
            font-family: 'Lato';
            font-style: normal;
            font-weight: 600;
            font-size: 32px;
            line-height: 36px;
            color: #FFFFFF;
            @include mobile {
                height: 115px;
                font-size: 20px;
                line-height: 23px;
            }
        }
        &-location {
            height: 18px;
            overflow: hidden;
            font-family: 'Lato';
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 18px;
            color: #FFFFFF;
            @include mobile {
                height: 16px;
                font-size: 14px;
                line-height: 16px;
            }
        }

        &-Review {
            display: flex;
            flex-direction: column;
            row-gap: 12px;
            &-Image {
                width: 82px;
                height: 82px;
                border-radius: 50%;
                @include mobile {
                    width: 62px;
                    height: 62px;
                }
            }
            &-Description {
                color: white;
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 24px;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 6;
                -webkit-box-orient: vertical;
                height: 14.2rem;
                @include mobile {
                    font-weight: 500;
                    font-size: 13px;
                    line-height: 15px;
                    height: 92px;
                }
            }
        }
        &-Customer {
            display: flex;
            column-gap: 12px;
            align-items: baseline;
            &-Name {
                color: white;
                font-family: 'Lato';
                font-style: normal;
                font-weight: 600;
                font-size: 17px;
                line-height: 18px;
                // color: #000000;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                height: 18px;
                @include mobile {
                    font-size: 15px;
                    line-height: 16px;
                    height: 16px;
                }
            }
            &-Country {
                width: 20px;
                height: 20px;
                border-radius: 50%;
            }
            .CountryFlags {
                width: 20px;
                height: 20px;
                border-radius: 50%;
                @include mobile {
                    width: 16px;
                    height: 16px;
                }
            }
        }
        &-Product {
            display: flex;
            column-gap: 15px;
            align-items: center;
            &-Image {
                width: 62px;
                height: 62px;
                border-radius: 50%;
                @include mobile {
                    width: 62px;
                    height: 62px;
                }
            }
            &-Name {
                color: white;
                font-family: 'Lato';
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 18px;
                // color: #000000;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                height: 2.2rem;
                @include mobile {
                    font-size: 14px;
                    line-height: 18px;
                    height: 22px;
                }
            }
            &-Link {
                color: white;
                font-family: 'Lato';
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                line-height: 17px;
                text-decoration: underline;
                @include mobile {
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 15px;
                }
            }
        }
    }
    .Testimonials {
        padding-block: 50px;
        @include mobile {
            margin-inline: auto;
            padding-block:30px;
        }
        &-Img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            opacity: 0.4;
        }
        &-title {
            font-family: 'Playfair Display';
            font-style: normal;
            font-weight: 400;
            font-size: 32px;
            line-height: 43px;
            text-align: center;
            color: #71333F;
            margin-bottom: 30px;
            @include mobile {
                margin-bottom: 20px;
            }
            @include mobile {
                font-size: 24px;
                font-weight: 600;
                line-height: 27px;
                background-color: #FFFFFF;
                // border: 2px solid rgba(0, 0, 0, 0.08);
                border-bottom: none;
            }
        }
        &-SubTitle {
            font-family: 'Lato';
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            line-height: 24px;
            text-align: center;
            color: #000000;
            margin-block: 24px 40px;
            @include mobile {
                font-size: 14px;
                margin-block: 0;
                padding-block: 0 12px;
                background-color: #FFFFFF;
                // border-inline: 2px solid rgba(0, 0, 0, 0.08);
            }
        }
        &-slides {
            width: 100%;
            margin-inline: auto;
            //max-width: var(--content-wrapper-width);
            max-width: 95%;
            margin-inline: auto 0%;
            overflow: hidden;
            @include mobile {
                left: 0;
                margin-inline: auto auto !important;
            }
            .slick {
                &-slide {
                    padding-inline-end: 24px;
                        @include mobile {
                            padding-inline-end: 0;
                        }
                    }
                &-list {
                    @include mobile {
                        border: 2px solid rgba(0, 0, 0, 0.08);
                        // border-top: none;
                    }
                }
                &-dots {
                    position: absolute;
                    bottom: 35px;
                    width: fit-content;
                    left: 50%;
                    transform: translateX(-50%);
                    @include mobile {
                        transform: translateX(calc(-50%));
                        width: 90%;
                        max-width: 90%;
                        overflow: scroll;
                        white-space: nowrap;
                        position: relative;
                        margin-top: 16px;
                        bottom: 0;
                    }
                }
            }
        }
        &-Container {
            background: #FFFFFF;
            border: 2px solid rgba(0, 0, 0, 0.08);
            /* margin-bottom: 95px; */
            display: flex !important;
            flex-direction: column;
            row-gap: 25px;
            padding: 24px;
            @include mobile {
                border: none;
                margin: 0;
            }
        }
        &-Review {
            display: flex;
            flex-direction: column;
            row-gap: 12px;
            &-Image {
                width: 82px;
                height: 82px;
                border-radius: 50%;
                @include mobile {
                    width: 62px;
                    height: 62px;
                }
            }
            &-Description {
                font-family: 'Lato';
                font-style: normal;
                font-weight: 400;
                font-size: 15px;
                line-height: 24px;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 6;
                -webkit-box-orient: vertical;
                height: 14.2rem;
                @include mobile {
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 15px;
                    overflow: hidden;
                    display: -webkit-box; 
                    -webkit-line-clamp: 4;
                    -webkit-box-orient: vertical;
                    height: 60px;
                    margin-top: 8px;
                }
            }
        }
        &-Customer {
            display: flex;
            column-gap: 12px;
            align-items: baseline;
            &-Name {
                font-family: 'Lato';
                font-style: normal;
                font-weight: 600;
                font-size: 15px;
                line-height: 15px;
                color: #000000;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                height: 15px;
                @include mobile {
                    font-size: 14px;
                    line-height: 14px;
                    height: 14px;
                }
            }
            &-Country {
                width: 20px;
                height: 20px;
                border-radius: 50%;
            }
            .CountryFlags {
                width: 20px;
                height: 20px;
                border-radius: 50%;
                @include mobile {
                    width: 16px;
                    height: 16px;
                }
            }
        }
        &-Product {
            display: flex;
            column-gap: 15px;
            align-items: center;
            &-Image {
                width: 62px;
                height: 62px;
                border-radius: 50%;
                @include mobile {
                    width: 62px;
                    height: 62px;
                }
            }
            &-Name {
                font-family: 'Lato';
                font-style: normal;
                font-weight: 600;
                font-size: 15px;
                line-height: 18px;
                color: #000000;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                height: 2.2rem;
                @include mobile {
                    font-size: 14px;
                    line-height: 18px;
                    height: 22px;
                }
            }
            &-Link {
                font-family: 'Lato';
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                line-height: 17px;
                text-decoration: underline;
                @include mobile {
                    font-weight: 500;
                    font-size: 11px;
                    line-height: 15px;
                }
            }
        }
    }
    .CheckNowBanner{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-inline: auto;
        // max-width: var(--content-wrapper-width);
        // min-height: 270px;
        // padding-block: 40px;
        row-gap: 20px;
        @media (max-width: 1200px) {
            padding-inline: 0%;
            padding-block: 0;
        }
        @include mobile {
            margin-top: 30px;
        }
        &-slides{
            display: flex;
            width: 100%;
            justify-content: space-between;
            // @include mobile {
                // justify-content: flex-start;
            // }
            @include mobile {
                > div > div, > div, .CustomSlider .HtmlSlider {
                    width: 100%;
                }
            }
            ul {
                @include mobile {
                    display: flex !important;
                    justify-content: center;
                }
                flex-direction: row;
                overflow: scroll;
                column-gap: 16px;
            }
        }
        &-Container{
            width: 100%;
            @include mobile {
                width: 100%;
                height: auto;
            }
        }
        &-Img {
            width: 100%;
            height: 100%;
        }
        &-Content{
            display: none;
            position: absolute;
            inset-block-end: 15%;
            inset-inline-start: 5%;
            width: 267px;
            z-index: 12;
            @include mobile {
                width: auto;
                inset-inline: 10px;
                inset-block-end: 12px;
            }
        }
        &-Text{
            font-family: 'Lato';
            font-style: italic;
            font-weight: 600;
            font-size: 20px;
            line-height: 24px;
            color: #FFFFFF;
            @include mobile {
                font-size: 10px;
                line-height: 12px;
            }
        }
        &-Link{
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            margin-block-start: 24px;
            width: fit-content;
            padding: 8px 20px;
            background-color: #000000;
            font-family: 'Lato';
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 15px;
            color: #FFFFFF;
            @include mobile {
                margin-block-start: 8px;
                padding: 0;
                font-size: 10px;
                line-height: 12px;
                width: 70px;
                height: 20px;
            }
            &:hover {
                background-color: white;
                color: #000000;
                border: 1px solid black;
            }
        }
    }
}