/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.CarouselScrollItem {
    display: flex;
    scroll-snap-align: start;
    // border: solid 1px var(--option-border-color);
    transition: border-color var(--animation-speed), filter var(--animation-speed);
    // filter: opacity(.5);
    flex: 1;
    cursor: pointer;
    width: 48px;
    height: 48px;
    // border-color: var(--option-border-color);
    border-radius: 2px;

    @include mobile {
        width: 12px;
        height: 12px;
        border: 1px solid;
        border-radius: 50%;
        margin-inline-start: 0;

        &_isActive {
            background-color: #000000;
        }
    }

    @include desktop {
        & + .CarouselScrollItem {
            margin-inline-start: var(--carousel-scroll-gap);
        }
    }

    &_isActive {
        // filter: opacity(1);
        // border-color: var(--option-border-color);
        border: 1px solid #000000;
    }

    &:focus,
    &:hover {
        filter: opacity(1);
    }
}
