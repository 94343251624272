/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/* stylelint-disable */
.BrandMenu {
    display: grid;
    grid-template-columns: 360px auto;
    @include mobile {
        display: block;
    }
    &Wrapper {
        .Menu-SubCategories {
            padding: 0;
        }
    }
    &-List {
        background-color: var(--color-white);
        &Content {
            padding-left: 20px;
            display: flex;
            margin-top: 0.625rem;
        }
    }
    &-SearchBar {
        padding: 20px;
        box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1);
        &Inner {
            width: 100%;
            max-width: 320px;
            .SearchIcon {
                position: absolute;
                left: 10px;
                top: 50%;
                z-index: 1;
                transform: translateY(-50%);
            }
            input {
                padding: 0 0 0 49px;
                width: 100%;
                height: 34px;
            }
        }
    }
    &-Brands {
        flex: 1;
        max-height: 538px;
        padding-bottom: 10px;
        overflow-x: hidden;
        overflow-y: scroll;
        &::-webkit-scrollbar {
            width: 0px;
        }
    }
    &-Alphabets {
        width: 50px;
        display: flex;
        flex-direction: column;
        gap: 5px;
    }
    &-Alphabet {
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        text-transform: uppercase;
        &_isActive, &:hover {
            color: var(--link-color);
        }
    }
    &-BrandGroup{
        margin-bottom: 20px;
        box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1);
        padding-bottom: 20px;
        &Heading {
            font-size: 14px;
            font-weight: 600;
            line-height: 20px;
            color: rgba(3, 2, 26, 0.6);
            text-transform: uppercase;
            margin-bottom: 0px;
            height: 58px;
            position: relative;
            border-bottom: none;
        }
        &Link {
            color: var(--color-black);
            display: block;
            font-weight: 400;
        }
    }

    &-Tabs {
        padding: 30px;
        @include mobile {
            display: none;
        }
        ul.tabs-navigation {
            display: flex !important;
            align-items: center;
            justify-content: center;
            gap: 15px;
            li.tab-header{
                border: none !important;
                background-color: #d8d8d8;
                &::before {
                    content: none;
                }
                &.ui-state-active {
                    background-color: var(--link-color) !important;
                    a {
                        span {
                            color: var(--color-white) !important;
                        }
                    }
                }
            }
        }
        .tabs-content {
            border: none !important;
            margin-top: 30px;
            figure {
                text-align: center !important;
            }
            img {
                max-width: 148px !important;
                object-fit: contain;
                object-position: center;
            }
        }
    }
}
