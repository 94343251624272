/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../../../../src/style/abstract/variables';
@import '../../../../../src/style/abstract/media';
@import '../../../../../src/style/abstract/button';
@import '../../../../../src/style/abstract/loader';
@import '../../../../../src/style/abstract/icons';
@import '../../../../../src/style/abstract/parts';

.SaleTimer {
    padding: 10px;
    margin: 10px;
    @include mobile {
        padding: 5px;
        margin: 5px;
    }
    &-Wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        max-width: 958px;
        margin-inline: auto;
    }
    &-Heading {
        font-size: 2.5rem;
        font-weight: 600;
        margin: 0;
        margin-block-end: 10px;
        @include mobile {
            font-size: 1.7rem;
            margin-block-end: 5px;
        }
    }
    &-Text {
        font-size: 2rem;
        margin: 0;
        text-align: right;
        @include mobile {
            font-size: 1.4rem;
            text-align: left;
        }
    }
    &-Right {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        flex-wrap: wrap;
        flex: 1;
        @include mobile {
            flex-wrap: nowrap;
        }
    }
    &-Countdown {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 10px;
        border-right: 2px solid #000;
        gap: 10px;
        flex: 0 0 80px;
        @include mobile {
            flex: 0 0 64px;
            padding: 5px;
            gap: 5px;
        }
        &:last-child {
            border-right: none;
        }
        &Heading {
            font-size: 3rem;
            font-weight: 600;
            @include mobile {
                font-size: 2rem;
            }
        }
        &Text {
            font-size: 1.6rem;
            margin: 0;
            text-transform: uppercase;
            @include mobile {
                font-size: 1.2rem;
            }
        }
    }
}
