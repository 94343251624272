/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

:root {
    --breadcrumbs-height: 40px;
    --breadcrumbs-active-color: #0a0903;
    --breadcrumbs-color: #888;
}

.Breadcrumbs {
    $crumb-padding: 11px;
    $arrow-size: 4px;

    //background-color: var(--color-white);

    @include mobile {
        --breadcrumbs-background: #fff;

        //padding-inline: 16px;
    }

    &-List {
        list-style: none;
        height: 45px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        overflow: scroll;
        -ms-overflow-style: none;
        scrollbar-width: none;
        
        &::-webkit-scrollbar {
            display: none;
        }
    }
}
