@import url('https://fonts.googleapis.com/css?family=Lato:ital,wght@100;200;300;400;500;600;700;800;900&display=swap');

/* Gotham */
/*
@font-face {
    font-family: 'Gotham';
    font-display: swap;
    font-weight: 900;
    src: local('Gotham'),
        url('../fonts/Gotham/Gotham-Black.otf') format('opentype');
}

@font-face {
    font-family: 'Gotham';
    font-display: swap;
    font-weight: 800;
    src: local('Gotham'),
        url('../fonts/Gotham/Gotham-Black.otf') format('opentype');
}

@font-face {
    font-family: 'Gotham';
    font-display: swap;
    font-weight: 700;
    src: local('Gotham'),
        url('../fonts/Gotham/Gotham-Bold.otf') format('opentype');
}

@font-face {
    font-family: 'Gotham';
    font-display: swap;
    font-weight: 600;
    src: local('Gotham'),
        url('../fonts/Gotham/Gotham-Bold.otf') format('opentype');
}

@font-face {
    font-family: 'Gotham';
    font-display: swap;
    font-weight: 500;
    src: local('Gotham'),
        url('../fonts/Gotham/Gotham-Bold.otf') format('opentype');
}

@font-face {
    font-family: 'Gotham';
    font-display: swap;
    font-weight: 400;
    src: local('Gotham'),
        url('../fonts/Gotham/Gotham-Light.otf') format('opentype');
}

@font-face {
    font-family: 'Gotham';
    font-display: swap;
    font-weight: 300;
    src: local('Gotham'),
        url('../fonts/Gotham/Gotham-Light.otf') format('opentype');
}

@font-face {
    font-family: 'Gotham';
    font-display: swap;
    font-weight: 200;
    src: local('Gotham'),
        url('../fonts/Gotham/Gotham-Thin.otf') format('opentype');
}

@font-face {
    font-family: 'Gotham';
    font-display: swap;
    font-weight: 100;
    src: local('Gotham'),
        url('../fonts/Gotham/Gotham-Thin.otf') format('opentype');
}
*/
/* PT Serif */
/*
@font-face {
    font-family: 'PT Serif';
    font-display: swap;
    font-weight: 900;
    src: local('PT Serif'),
        url('../fonts/PT_Serif/PTSerif-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'PT Serif';
    font-display: swap;
    font-weight: 800;
    src: local('PT Serif'),
        url('../fonts/PT_Serif/PTSerif-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'PT Serif';
    font-display: swap;
    font-weight: 700;
    src: local('PT Serif'),
        url('../fonts/PT_Serif/PTSerif-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'PT Serif';
    font-display: swap;
    font-weight: 600;
    src: local('PT Serif'),
        url('../fonts/PT_Serif/PTSerif-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'PT Serif';
    font-display: swap;
    font-weight: 500;
    src: local('PT Serif'),
        url('../fonts/PT_Serif/PTSerif-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'PT Serif';
    font-display: swap;
    font-weight: 400;
    src: local('PT Serif'),
        url('../fonts/PT_Serif/PTSerif-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'PT Serif';
    font-display: swap;
    font-style: italic;
    font-weight: 800;
    src: local('PT Serif'),
        url('../fonts/PT_Serif/PTSerif-BoldItalic.ttf') format('truetype');
}

@font-face {
    font-family: 'PT Serif';
    font-display: swap;
    font-style: italic;
    font-weight: 700;
    src: local('PT Serif'),
        url('../fonts/PT_Serif/PTSerif-BoldItalic.ttf') format('truetype');
}

@font-face {
    font-family: 'PT Serif';
    font-display: swap;
    font-style: italic;
    font-weight: 600;
    src: local('PT Serif'),
        url('../fonts/PT_Serif/PTSerif-BoldItalic.ttf') format('truetype');
}

@font-face {
    font-family: 'PT Serif';
    font-display: swap;
    font-style: italic;
    font-weight: 500;
    src: local('PT Serif'),
        url('../fonts/PT_Serif/PTSerif-Italic.ttf') format('truetype');
}

@font-face {
    font-family: 'PT Serif';
    font-display: swap;
    font-style: italic;
    font-weight: 400;
    src: local('PT Serif'),
        url('../fonts/PT_Serif/PTSerif-Italic.ttf') format('truetype');
}
*/
/* Poppins */

@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-display: swap;
    font-weight: 900;
    src: local('Poppins'),
        url('../fonts/Poppins/Poppins-Black.ttf') format('truetype');
}

@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-display: swap;
    font-weight: 800;
    src: local('Poppins'),
        url('../fonts/Poppins/Poppins-ExtraBold.ttf') format('truetype');
}

@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-display: swap;
    font-weight: 700;
    src: local('Poppins'),
        url('../fonts/Poppins/Poppins-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-display: swap;
    font-weight: 600;
    src: local('Poppins'),
        url('../fonts/Poppins/Poppins-SemiBold.ttf') format('truetype');
}

@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-display: swap;
    font-weight: 500;
    src: local('Poppins'),
        url('../fonts/Poppins/Poppins-Medium.ttf') format('truetype');
}

@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-display: swap;
    font-weight: 400;
    src: local('Poppins'),
        url('../fonts/Poppins/Poppins-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-display: swap;
    font-weight: 300;
    src: local('Poppins'),
        url('../fonts/Poppins/Poppins-Light.ttf') format('truetype');
}

@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-display: swap;
    font-weight: 200;
    src: local('Poppins'),
        url('../fonts/Poppins/Poppins-ExtraLight.ttf') format('truetype');
}

@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-display: swap;
    font-weight: 100;
    src: local('Poppins'),
        url('../fonts/Poppins/Poppins-Thin.ttf') format('truetype');
}

@font-face {
    font-family: 'Poppins';
    font-style: italic;
    font-display: swap;
    font-weight: 900;
    src: local('Poppins'),
        url('../fonts/Poppins/Poppins-BlackItalic.ttf') format('truetype');
}

@font-face {
    font-family: 'Poppins';
    font-style: italic;
    font-display: swap;
    font-weight: 800;
    src: local('Poppins'),
        url('../fonts/Poppins/Poppins-ExtraBoldItalic.ttf') format('truetype');
}

@font-face {
    font-family: 'Poppins';
    font-style: italic;
    font-display: swap;
    font-weight: 700;
    src: local('Poppins'),
        url('../fonts/Poppins/Poppins-BoldItalic.ttf') format('truetype');
}

@font-face {
    font-family: 'Poppins';
    font-style: italic;
    font-display: swap;
    font-weight: 600;
    src: local('Poppins'),
        url('../fonts/Poppins/Poppins-SemiBoldItalic.ttf') format('truetype');
}

@font-face {
    font-family: 'Poppins';
    font-style: italic;
    font-display: swap;
    font-weight: 500;
    src: local('Poppins'),
        url('../fonts/Poppins/Poppins-MediumItalic.ttf') format('truetype');
}

@font-face {
    font-family: 'Poppins';
    font-style: italic;
    font-display: swap;
    font-weight: 400;
    src: local('Poppins'),
        url('../fonts/Poppins/Poppins-Italic.ttf') format('truetype');
}

@font-face {
    font-family: 'Poppins';
    font-style: italic;
    font-display: swap;
    font-weight: 300;
    src: local('Poppins'),
        url('../fonts/Poppins/Poppins-LightItalic.ttf') format('truetype');
}

@font-face {
    font-family: 'Poppins';
    font-style: italic;
    font-display: swap;
    font-weight: 200;
    src: local('Poppins'),
        url('../fonts/Poppins/Poppins-ExtraLightItalic.ttf') format('truetype');
}

@font-face {
    font-family: 'Poppins';
    font-style: italic;
    font-display: swap;
    font-weight: 100;
    src: local('Poppins'),
        url('../fonts/Poppins/Poppins-ThinItalic.ttf') format('truetype');
}

// Extra
/* PP Pangram Sans */
/*
@font-face {
    font-family: 'PP Pangram Sans';
    font-display: swap;
    font-weight: lighter;
    src: local('PP Pangram Sans'),
        url('../fonts/PP Pangram Sans/PPPangramSans-Light.otf') format('opentype');
}

@font-face {
    font-family: 'PP Pangram Sans';
    font-display: swap;
    font-weight: 500;
    src: local('PP Pangram Sans'),
        url('../fonts/PP Pangram Sans/PPPangramSans-Medium.otf') format('opentype');
}

@font-face {
    font-family: 'PP Pangram Sans';
    font-display: swap;
    font-weight: 600;
    src: local('PP Pangram Sans'),
        url('../fonts/PP Pangram Sans/PPPangramSans-NarrowSemibold.otf') format('opentype');
}

@font-face {
    font-family: 'PP Pangram Sans';
    font-display: swap;
    font-weight: 900;
    src: local('PP Pangram Sans'),
        url('../fonts/PP Pangram Sans/PPPangramSans-Bold.otf') format('opentype');
}
*/
/* Agrandir */

@font-face {
    font-family: 'Agrandir';
    font-display: swap;
    font-weight: 900;
    src: local('Agrandir'),
        url('../fonts/Agrandir/Agrandir Wide Black Italic 900.otf') format('opentype');
}

@font-face {
    font-family: 'Agrandir';
    font-display: swap;
    font-weight: 800;
    src: local('Agrandir'),
        url('../fonts/Agrandir/Agrandir Grand Heavy 800.otf') format('opentype');
}

@font-face {
    font-family: 'Agrandir';
    font-display: swap;
    font-weight: 700;
    src: local('Agrandir'),
        url('../fonts/Agrandir/Agrandir Text Bold 700.otf') format('opentype');
}

@font-face {
    font-family: 'Agrandir';
    font-display: swap;
    font-weight: 400;
    src: local('Agrandir'),
        url('../fonts/Agrandir/Agrandir Regular 400.otf') format('opentype');
}

