/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/* stylelint-disable */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

 .HomeBanner {
    @include desktop {
        min-height: 369px;
    }
    @include mobile {
        min-height: 188px;
        padding-inline: 16px;
        padding-bottom: 0px;
    }
    img{
        width: 100%;
    }
}

.LastLine{
    height: 240px;
    @include mobile {
        display: none;
    }
    &-wrap{
        display: flex;
        flex-direction: row;
        padding-block: 80px;
        @include desktop {
            justify-content: space-evenly;
        }
        @include mobile {
            overflow: scroll;
            padding: 0;
        }
    }
    &-Container {
        display: flex;
        align-items: center;
        column-gap: 16px;

        svg {
            width: 80px;
            height: 80px;
        }

        @include mobile {
            min-width: 116px;
            max-width: 116px;
            flex-direction: column;
            align-items: center;

            svg {
                width: 64px;
                height: 64px;
            }
        }
    }
    &-Name {
        display: flex;
        flex-direction: column;
        row-gap: 4px;
        @include mobile {
            padding-inline: 4px;
            text-align: center;
        }
    }
    &-Title {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        color: #000000;
        @include mobile {
            font-size: 12px;
            line-height: 15px;
        }
    }
    &-Text {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: #8B8B8B;
        @include mobile {
            font-size: 12px;
            line-height: 15px;
        }
        
    }
    &-Divider {
        border: 1px solid #CEBC89;
        @include mobile {
            border: 1px solid #E8E8E8;
        }
    }
}
:root {
    --footer-content-height: 220px;

    @include mobile {
        --footer-content-height: 110px;
    }
    --footer-copyright-height: 50px;
    --footer-nav-height: 55px;
    --footer-total-height: calc(env(safe-area-inset-bottom) + var(--footer-nav-height));
    --footer-margin-bottom: 55px;
}

.Footer {
    $column-count: 4;

    border-top: 1px solid #ccc;
    padding-inline: 5%;
    background-color: #161616;
    
    a {
        color: #8B8B8B;
        line-height: 22px;
    }

    @include mobile {
        margin-block-end: var(--navigation-tabs-height);
    }

    &-CopyrightContentWrapper {
        background-color: var(--secondary-base-color);
    }

    &-CopyrightContent {
        padding: 10px 0;
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: var(--footer-copyright-height);

        @include desktop {
            padding-block-end: 48px;
        }
    }

    &-Copyright {
        font-size: 12px;
        text-align: center;
        color: var(--secondary-dark-color);
        padding-inline: 16px;
        line-height: 16px;

        a {
            color: inherit;
            font-weight: 400;
            font-size: 12px;

            &:hover {
                color: var(--primary-base-color);
            }
        }
    }

    &-Column-Center {
        width: 100%;
    }
    
    &-Columns {
        display: flex;
        height: 100%;
        justify-content: space-between;

        &-Bottom {
            display: flex;
            justify-content: space-between;
            @include mobile {
                flex-direction: column;
                align-items: flex-start;
                text-align: center;
            }
        }

        @include mobile {
            flex-direction: column;
            align-items: flex-start;
            text-align: left;
            padding: 25px 16px;
        }

        @include desktop {
            padding-inline: 0;
            padding-block-start: 62px;
            padding-block-end: 62px;
        }

        @include tablet {
            padding-inline: 30px;
        }
    }

    &-Column {
        @include mobile {
            width: 100%;
            text-align: left;
        }

        &:not(:last-of-type) {
            @include mobile {
                margin-block-end: 30px;
            }

            @include desktop {
                padding-inline-end: 20px;
                max-width: calc(100% / #{ $column-count - 1 });
            }
        }
    }

    &-ColumnTitle {
        color: white;
        margin: 0;
        margin-block-end: 15px;

        &-Title {
            font-family: 'Lato', 'Lato';
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 18px;
            @include mobile {
                font-size: 14px;                
            }
        }

        @include desktop {
            margin-block-end: 20px;
        }
    }

    &-ColumnContent {
        display: flex;
        flex-direction: column;
        @include mobile {
            align-items: start;
        }

        &-Icons {
            width: 100px;
            display: flex;
            justify-content: space-between;
        }

        .news {
            &-Wrap {
                display: flex;
                @include mobile {
                    margin-bottom: 40px;
                }
            }

            &-Input {
                width: 302px;
                height: 30px;
                background: #383838;
                font-family: 'Lato';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 16px;
                text-align: center;
                color: #8B8B8B;
                border: none;
                text-align: left;
                @include mobile {
                    width: 220px;
                }
            }

            &-Button {
                width: 77px;
                height: 32px;
                background: #EF676C;
                font-family: 'Lato';
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 19px;
                color: #FFFFFF;
                margin-left: 20px;
                @include mobile {
                    width: 58px;
                }
            }
        }

        &_direction_horizontal {
            flex-direction: row;

            @include mobile {
                justify-content: center;
            }
        }
    }

    &-ColumnItem {
        border-radius: 2px;
        color: inherit;
        margin-block-end: 12px;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;

        &:last-of-type {
            margin: 0;
        }

        &:hover {
            text-decoration: underline;
        }

        &Image {
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                // important required as default 100% width is passed as inline style
                // + you can't achieve same centering with just passing '16px' as inline style for the case
                width: 16px!important;
                inset-block-start: 50%;
                inset-inline-start: 50%;
                transform: translate(-50%, -50%);

                &:dir(rtl) {
                    transform: translate(50%, -50%);
                }

                &:hover {
                    opacity: 0.6;
                }
            }
        }

        &_type_image {
            width: 40px;
            height: 40px;
            border: 1px solid var(--secondary-dark-color);

            &:not(:last-of-type) {
                margin-inline-end: 20px;
            }
        }
    }

    &-CmsBlockWrapper {
        .CmsBlock-Wrapper {
            flex: 1;
        }

        .ContentWrapper .Footer-Columns {
            padding: 0;
        }

        .Footer-Column_isNewsletter {
            @include desktop {
                padding-inline-start: 100px;
            }
        }
    }

    &-Content {
        min-height: var(--footer-content-height);
        display: flex;
        gap: 78px;

        .pagebuilder-column {
            @include mobile {
                margin-inline-end: 0;

                >figure {
                    max-width: 120px;
                }
            }
        }

        font-size: 14px;
        font-weight: 400;
        line-height: 21px;

        @include mobile {
            text-align: start;
        }
    }

    &-Logo {
        flex: 0 0 150px;

        a {
            display: block;
        }
    }

    &-Description {
        max-width: 264px;

        .footer_title {
            margin-block-start: 0;
        }
    }

    &-Link {
        flex: 0 0 300px;

        ul {
            li {
                &::before {
                    content: none;
                }

                a {
                    color: var(--color-black)
                }
            }
        }
    }

    &-Heading {
        font-size: 14px;
        font-weight: 500;
        line-height: 17px;
        text-transform: uppercase;
        font-family: var(--default-font);
        letter-spacing: .3em;

        @include mobile {
            text-align: start;
        }
    }

    &-Links {
        a {
            // color: currentColor;
            font-weight: 400;
            // line-height: 1.5;
            font-size: 16px;
            color: var(--color-black);
            line-height: 24px;
        }
    }

    &-Mobile {
        margin-block-end: var(--footer-margin-bottom);

        &_isOpen {
            margin-block-end: 20px;
        }

        &Toggle {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 10px;
            font-size: 14px;
            background: #161616;
            color: white;
        }
    }

    &-Caret {
        font-size: 23px;
        line-height: 24px;
        transform: rotate(90deg);

        &_isOpen {
            transform: rotate(-90deg);
        }
    }

}
.DownFooter {
    &-top{
        height: 68px;
        background: #FFEAF0;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 18px;
        span {
            font-family: 'Lato';
            font-style: normal;
            font-weight: 400;
            font-size: 24px;
            line-height: 28px;
            color: #000000;
            margin-right: 4px;
        }

        a {
            font-family: 'Sharp Sans';
            font-style: normal;
            font-weight: 700;
            font-size: 24px;
            line-height: 32px;
            color: #DE4D47;
        }
    }
    &-bottom{
        height: 82px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding-inline: 5%;
    }
    &-left{
        font-family: 'Lato';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 32px;
        color: #8B8B8B;
    }
    &-right{
        display: flex;
        gap: 15px;

        a {
            font-family: 'Lato';
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 32px;
            color: #8B8B8B;
        }

        span {
            font-family: 'Lato';
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 32px;
            color: #8B8B8B;
        }
    }
}
