/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/* stylelint-disable */ 
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

:root {
    --search-bar-max-width: 450px;
    --search-bar-width: 270px;
    --search-clear-background: var(--secondary-base-color);
    --search-field-background: var(--secondary-base-color);
    --search-field-border-color: var(--primary-divider-color);
    --search-field-color: var(--secondary-base-color);
    --search-field-visible-background: #fff;
    --search-input-desktop-border-color: #000;
}

::placeholder {
    overflow: visible;
}

.SearchField {
    display: none;
    // margin: 8px auto;
    max-width: 100%;
    width: 100%;
    //border-radius: 80px;
    //overflow: hidden;
    background-color: white;
    box-shadow: none;

    @include desktop {
        display: block;
        // width: 90%;
        max-width: 600px;
        // grid-column: 2 / 3;
    }

    &_isVisible {
        display: block;

        @include mobile {
            margin-block-start: 16px;
        }
    }

    &_isActive {
        .SearchOverlay {
            &-Results {
                display: block;
            }
        }
    }

    &-CloseIcon,
    &-SearchIcon {
        position: absolute;
        height: 24px;
        inset-inline-start: 24px;
        inset-block-start: calc(50% - 12px);
        width: 24px!important;
    }

    &-Input {
        width: 100%;
        background: #F0F0F0;
        padding-inline: calc(24px + 24px + 16px) 24px;
        height: 40px;
        padding-block: 0;
        border-radius: 500px;
        & , &::placeholder{
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            color: #8B8B8B;
        }
        color: #000;
        &:focus{
            border: inherit;
        }
    }
}
