/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/* stylelint-disable */ 
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

.CmsPage {
    &_isBreadcrumbsHidden {
        margin-block-start: calc(var(--header-total-height) + 12px);

        @include mobile {
            margin-block-start: var(--header-total-height);
        }
    }

    &-Content {
        @include mobile {
            max-width: 100%;
            overflow-x: hidden;
        }

        h2 {
            @include mobile {
                text-align: center;
            }
        }
    }

    &-Wrapper {
        grid-template-columns: auto;
        max-width: var(--content-wrapper-width);
        padding-inline: 32px;
        margin-block-start: calc(var(--header-total-height) + 12px);
        // margin-block-end: var(--header-nav-height);
        margin-inline: auto;
        word-wrap: break-word;

        @include mobile {
            padding-inline: 14px;
            margin-block-start: var(--header-total-height);
        }

        @include desktop {
            margin-block-start: 24px;
        }

        &_page_width {
            &_full {
                max-width: 100%;
                padding-inline: 0;
            }
        }
    }

    &-Heading {
        margin-block-end: 18px;

        @include mobile {
            margin-block-end: 21px;
            display: none;
        }
    }

    &-SectionPlaceholder {
        background-image: var(--placeholder-image);
        background-size: var(--placeholder-size);
        animation: var(--placeholder-animation);
        margin: 0;
        margin-block-end: 10px;
        padding-block-end: 30%;

        @include mobile {
            padding-block-end: 60%;
        }

        &:first-child {
            padding-block-end: 40%;

            @include mobile {
                padding-block-end: 100%;
            }
        }
    }

    .TextPlaceholder {
        margin-block-end: 22px;
        display: block;

        @include mobile {
            margin-block-end: 25px;
        }
    }

    .Slider-Crumbs {
        position: unset;
        margin-block: 16px 40px;

        @include mobile {
            margin-block: 20px 28px;
        }
    }

    h3 {
        @include mobile {
            font-size: 14px;
        }
    }

    ul,
    ol {
        li {
            &::before {
                position: relative;
                margin-inline-start: 10px;
                margin-inline-end: 5px;
                inset-block-start: -1px;
            }
        }
    }
    .Homepage-CleanAndSustainable {
        min-width: 99vw;
        margin-left: auto;
        margin-right: auto;
        margin-top: 1.5rem;
        @include before-desktop {
            margin-left: calc(49% - 50vw);
        }
        .background1 {
            position: absolute;
            width: 50vw;
            right: 50%;
            background: linear-gradient(#bedcff, #a7efff);
            height: 100%;
        }
        .background2 {
            position: absolute;
            background: #d0f7ff;
            width: 50vw;
            left: 50%;
            height: 100%;
            @include before-desktop {
                width: 49.6vw;
                left: 49.7%;
            }
        }
        .wrapper {
            display: flex;
            font-family: "Popins";
            margin: auto;
            @include desktop {
                max-width: var(--content-wrapper-width);
            }
            .ColumnOne {
                background: linear-gradient(#bedcff, #a7efff);
                flex: 1.5;
                @include mobile {
                    padding-top: 2rem;
                }
                @include after-mobile {
                    padding-top: 4rem;
                }
                .Content {
                    display: flex;
                    @include mobile {
                        padding-left: 8%;
                    }
                    .Header {
                        @include desktop {
                            font-size: 72px;
                            line-height: 78px;
                        }
                        @media (min-width: 768px) and (max-width: 1150px) {
                            font-size: 52px;
                        }
                        @include mobile {
                            font-size: 2.4rem;
                            max-width: 225px;
                        }
                        @include after-mobile {
                            margin-left: 2rem;
                        }
                        background: unset;
                        color: #000e8b;
                        max-width: 456px;
                    }
                }
            }

            .ColumnTwo {
                background: #d0f7ff;
                flex: 0.3;
                @include after-mobile {
                    padding-bottom: 310px;
                    flex: 2;
                }
                .Logos {
                    display: grid;
                    @include after-mobile {
                        grid-template-columns: repeat(3, auto);
                        justify-content: center;
                        padding-top: 7rem;
                    }
                    @include mobile {
                        padding-top: 4rem;
                        padding-bottom: 4rem;
                    }
                    @include desktop {
                        grid-gap: 6rem;
                    }
                    @include tablet {
                        grid-gap: 3rem;
                    }
                }
                .Logo {
                    width: 100px;
                    margin: auto;
                    @include mobile {
                        width: 60px;
                    }
                }
            }
            .ColumnThree {
                display: flex;
                position: absolute;
                @include after-mobile {
                    left: 50%;
                    transform: translate(-50%, 0);
                    width: 80%;
                }
                @include mobile {
                    width: 100%;
                    position: unset;
                }
                bottom: 3%;
                .Content {
                    text-align: center;
                    background: white;
                    padding: 4rem 3rem;
                    width: 100%;
                    @include mobile {
                        padding: 2rem;
                    }
                    .Detail {
                        font-family: "Lato-Regular";
                        margin-bottom: 1rem;
                        font-size: 14px;
                        cursor: pointer;
                        @include mobile {
                            font-size: 12px;
                            display: flex;
                            flex-direction: column;
                            max-width: 300px;
                            margin: auto;
                        }
                    }

                    .SubHeader {
                        color: #51abff;
                        font-size: 32px;
                        @include mobile {
                            font-size: 16px;
                        }
                        font-weight: 600;
                        margin: unset;
                    }

                    .Text {
                        margin-right: 0.5rem;
                    }

                    .link {
                        color: #2252fe;
                        font-weight: 600;
                        text-decoration: underline;
                    }

                    .Logos {
                        max-width: 600px;
                        margin-right: auto;
                        margin-left: auto;
                        @include after-mobile {
                            display: flex;
                            justify-content: space-evenly;
                            margin-top: 5rem;
                        }
                        @include mobile {
                            display: grid;
                            grid-template-columns: repeat(3, 1fr);
                            max-width: 250px;
                        }
                        .Logo {
                            @include after-mobile {
                                width: 65px;
                            }
                            @include mobile {
                                width: 60px;
                            }
                            &:last-child {
                                padding: 0.8rem;
                            }
                        }
                    }
                }
            }
        }
    }
}
