/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/* stylelint-disable */ 
.AdsSlider {
    .slick-dots > li > button {
        width: 12px;
        height: 12px;
        padding: 5px;
        border: none;
        border-radius: 50%;
        background: #0d4c99;
        opacity: 0.4;
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
        &::before {
            display: none;
        }
    }
    .slick-active > button{
        background: #0d4c99 !important;
        opacity: 1 !important;
    }
    .slick-dots {
        position: absolute;
        bottom: 0px;
        li::before {
            display: none;
        }
        @include mobile {
            bottom: 0px;
        }
    }
    // .slick-slider{
    //     @include mobile {
    //         padding-bottom: 13px;
    //     }
    // }

    .slick {
        &-track:last-child {
            display: none;
        }
        &-arrow {
            width: 45px;
            height: 45px;
            z-index: 4;
            @include mobile {
                width: 42px;
                height: 42px;
            }
        }
        &-prev {
            left: 2%;
            opacity: 0.5;
            background: white;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            svg {
                height: auto;
                transform: rotate(180deg);
            }
            &::before {
                display: none;
            }
        }
        &-next {
            right: 2%;
            opacity: 0.5;
            background: white;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            svg {
                height: auto;
            }
            &::before {
                display: none;
            }
        }
    }

}
