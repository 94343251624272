/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../../../../src/style/abstract/variables';
@import '../../../../../src/style/abstract/media';
@import '../../../../../src/style/abstract/button';
@import '../../../../../src/style/abstract/loader';
@import '../../../../../src/style/abstract/icons';
@import '../../../../../src/style/abstract/parts';

.SocialLogin {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    margin-top: 15px;
    &-Content {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }
    &-Actions {
        > a {
            display: block;
        } 
    }
}
.SocialLogin {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    margin-top: 15px;
    &-Content {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }
    &-Actions {
        > a {
            display: block;
        } 
    }
    &-Or {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 18px;
        color: var(--color-black);
        margin-bottom: 24px;
        text-align: center;
    }
    &-google {
        display: flex;
        align-items: center;
        justify-content: center;
        background: #DE4D47;
        padding: 8px 16px;
        width: 100%;
        margin-block-end: 16px;
        text-align: center;
        span {
            color: #FFF;
            font-size: 16px;
            font-family: 'Lato';
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }
        img {
            margin-right: 8px;
            width: 20px;
            height: 20px;
        }
    }
    &-facebook {
        display: flex;
        align-items: center;
        justify-content: center;
        background: #4968AD;
        padding: 8px 16px;
        width: 100%;
        margin-block-end: 16px;
        text-align: center;
        span {
            color: #FFF;
            font-size: 16px;
            font-family: 'Lato';
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }
        img {
            margin-right: 8px;
            width: 20px;
            height: 20px;
        }
    }
}
