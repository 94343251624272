/* stylelint-disable */ 
.clean_and_sustainable_beauty_modal {
    &.modal_open {
        display: flex !important;
    }
}

.About-Us {
    &-Description {
        margin-top: 3rem;
        margin-left: auto;
        margin-right: auto;
        max-width: 100%;
        min-width: 99vw;
        @include tablet {
            padding: 3rem;
        }
        @include before-desktop {
            margin-left: calc(49.4% - 49.6vw);
        }
        @include desktop {
            padding: 4rem 6rem;
        }
        @include mobile {
            margin-left: 0;
        }
        .DescriptionHeader {
            color: #000e8b;
            font-family: Popins-extra;
            margin: 1rem 0;
        }
        li {
            @include desktop {
                font-size: 20px;
            }
        }
    }
    &-Banner {
        display: flex;
        min-width: 99vw;
        margin-left: auto;
        margin-right: auto;
        @include before-desktop {
            margin-left: calc(49% - 50vw);
        }
        .Image {
            padding-bottom: unset;
            height: 100%;
            &-Image {
                position: relative;
            }
        }
    }
    &.Homepage-CleanAndSustainable {
        margin-top: 5rem;
        @include before-desktop {
            min-width: unset;
            margin: unset;
        }
        .wrapper {
            padding-bottom: 6rem;
            @include before-desktop {
                padding: 3rem 1rem;
                display: unset;
            }
            .ColumnTwo,
            .ColumnOne {
                background: #b1c8e8;
                @include before-desktop {
                    flex: unset;
                    display: none;
                }
            }
            .ColumnThree {
                bottom: 14%;
                @include before-desktop {
                    flex: unset;
                    position: relative;
                    left: unset;
                    width: unset;
                    transform: unset;
                }
            }
        }
        .background2,
        .background1 {
            background: #b1c8e8;
        }
    }
    &-Cards {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 1rem;
        grid-row-gap: 1rem;
        max-width: var(--content-wrapper-width);
        margin: auto;
        @include before-desktop {
            grid-template-columns: repeat(1, 1fr);
        }
        @include tablet {
            grid-row-gap: 3rem;
        }
        @include desktop {
            grid-column-gap: 3rem;
            grid-row-gap: 3rem;
        }
        .About-Us-Card {
            display: grid;
            grid-template-columns: 1fr 1fr;
            border: 2px solid #ffcbb39e;
            .Image-Image {
                object-fit: cover;
            }
            @include before-desktop {
                grid-template-columns: repeat(1, 1fr);
                .Image {
                    height: 100%;
                    padding-bottom: 0;
                    margin: 0;
                    display: flex;
                    &-Image {
                        position: relative;
                    }
                }
            }
            &-Paragraph {
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 21px;
                letter-spacing: 0.015em;
                color: rgba(47, 47, 47, 0.9);
                margin: 2rem;
            }
            &-info {
                background: white;
                h2 {
                    &.About-Us-Card-Header {
                        font-weight: bold;
                        font-size: 24px;
                        line-height: 29px;
                        letter-spacing: 0.015em;
                        text-transform: lowercase;
                        color: rgba(47, 47, 47, 0.9);
                        margin: 2rem;
                    }
                }
            }
        }
        &-Section {
            background: #fadbe2;
            display: flex;
            padding: 1rem;
            min-width: 99vw;
            margin-left: auto;
            margin-right: auto;
            @include tablet {
                padding: 3rem;
            }
            @include before-desktop {
                margin-left: calc(49.4% - 49.6vw);
            }
            @include desktop {
                padding: 4rem 6rem;
            }
        }
    }

    &-modal {
        display: none;
        position: fixed;
        z-index: 1;
        //padding-top: 100px;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        overflow: auto;
        background-color: rgb(0, 0, 0);
        background-color: rgba(0, 0, 0, 0.4);
    }

    &-modal-content {
        background-color: #fefefe;
        margin: auto;
        border: 1px solid #888;
        width: 80%;
        padding: unset;
        height: 80%;
        overflow: visible;
        h2.Popup-Heading {
            /* width: 80%; */
            border-bottom: 1px solid #d3d3d3;
            padding-bottom: 1rem;
            font-weight: 800;
            color: #000e8b;
            font-size: larger;
        }

        .About-Us-Popup-Content {
            .Popup-Heading {
                padding: 2rem;
            }
        }

        .Popup-Tagline {
            margin: 0 2rem;
        }

        .PopupContent {
            font-size: 14px;
            overflow-y: scroll;
            border: 1px solid #d3d3;
            padding: 5px;
            margin: 2rem 0 2rem 2rem;
            overflow-x: revert;
            word-break: break-all;
            width: calc(100% - 4rem);
        }

        .Popup-Content-Text {
            margin-bottom: 5px;
        }

        .Popup-Content-Heading {
            font-weight: 600;
        }

        .About-Us-Popup-Content {
            height: 100%;
            display: flex;
            flex-direction: column;
        }
    }

    &-close {
        color: #aaaaaa;
        float: right;
        font-size: 28px;
        font-weight: bold;
        margin-left: auto;
        z-index: 99;
        padding: 0 10px;
        position: absolute;
        right: 0;
    }

    &-close:hover,
    &-close:focus {
        color: #000;
        text-decoration: none;
        cursor: pointer;
    }
}
