/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import 'abstract/variables';
@import 'abstract/media';
@import 'abstract/button';
@import 'abstract/loader';
@import 'abstract/icons';
@import 'abstract/parts';

/* stylelint-disable */ 
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ('OSL') v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import './abstract/abstract';
@import './base/accordion';
@import './base/breakpoints';
@import './base/keyframes';
@import './base/root';
@import './base/reset';
@import './base/button';
@import './base/list';
@import './base/link';
@import './base/table';
@import './base/input';
@import './base/fieldset';
@import './base/blockquote';
@import './cms/block';
@import './AboutUsModal';
@import './fonts.scss';

body.isLocal {
    iframe {
        display: none;
    }
}

.slick {
    &-slider{
        overflow: hidden;
    }
    &-track {
        display: flex;
    }
    &-dots {
        position: absolute;
        bottom: 16px!important;
        @include mobile {
            position: relative!important;
            inset-block-end: 0px!important;
        }
        & > li {
            &::before {
                content: none;
            }
            & > button {
                background: black!important;
                opacity: 0.4;
                width: 14px!important;
                height: 14px!important;
                box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
                border: none!important;
                border-radius: 50%!important;
            }
            & > button:before {
                content: none;
            }
            @include mobile {
                width: 12px!important;
                height: 12px!important;
                & > button {
                    width: 12px!important;
                    height: 12px!important;
                }
            }
        }
    }
    &-active {
        & > button {
            background: black !important;
            opacity: 1 !important;
            width: 16px;
            height: 16px;
            box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
            @include mobile {   
                border: 1px solid!important;
                border-radius: 50%!important;
                padding: 0!important;
                cursor: pointer!important;
                border: 1px solid!important;
                border-radius: 50%!important;
                background: black!important;
            }
        }
    }
}

* {
    font-family: 'Lato';
}